<template>
  <div class="col-12">
    <div class="dashbox">
      <div class="dashbox-title">
        <h1 class="mx-auto">Ipakilala ang players</h1>
      </div>
      <div class="text-center">
        <p>Ang iyong referral link:</p>
        <p v-if="isLoggedIn" class="link-ctv">
          {{ referralLink }}
          <button class="btn copy-btn" @click="copyRewardContent(referralLink)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-clipboard"
              viewBox="0 0 16 16"
            >
              <path
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"
              />
              <path
                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"
              />
            </svg>
          </button>
        </p>
        <p v-if="!isLoggedIn">
          <router-link to="/log-in">MAG-LOG IN</router-link> O
          <router-link to="/register"> MAGREGISTER</router-link> PARA MAKAKUHA
          NG REFERRAL LINK
        </p>
        <p class="line-height-30">
          <span class="attention-text">Ang referral ng manlalaro</span> ay isang
          program na tumutulong sa iyong makatanggap ng rewards pagkatapos ng
          bawat referral ng isang bagong manlalaro sa amin<br />
          Makakatanggap ka ng reward kung naabot ng iyong player ang sumusunod
        </p>

        <TableCtv />
        <div class="row">
          <TableCtvHistory />
          <tableRule />
        </div>

        <p class="mt-2 line-height-30">
          <i class="small-notes"
            >- Dapat i-link ng mga inimbitahang user ang Telegram at Gcash
            wallet<br />
            - Para sa 1 User na inimbitahan mo, kapag naglaro ang user at umabot
            sa bet level na 20,000 pesos, ang maximum amount na matatanggap mo
            ay 1000 pesos.<br />
            - Sa lahat ng case ng paggawa ng mass account o mga signs ng
            pandaraya, tatanggi kami at hindi lulutasin ang mga concern</i
          >
        </p>
        <div class="col-12 col-xl-6 mx-auto">
          <p>Nangungunang referer ngayong linggo:</p>
          <div class="dashbox p-0">
            <table class="game-table table-center">
              <thead>
                <tr>
                  <th></th>
                  <th>User</th>
                  <th>Bilang ng mga taong inimbitahan</th>
                  <th>Reward</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>than1****</td>
                  <td>1</td>
                  <td>????</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>llhhg****</td>
                  <td>1</td>
                  <td>????</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableCtvHistory from "@/components/tableCtvHistory.vue";
import TableCtv from "../components/tableCtv";
import TableRule from "@/components/tableRule.vue";
import { getCurrentUser } from "@/api/user";
import EventBus from "@/js/eventBus";
import { useHead } from "@vueuse/head";

export default {
  name: "Tro_thanh_ctvPage",
  setup() {
    useHead({
      title: "Maging collaborator",
      link: [
        {
          rel: "canonical",
          href: "https://luckygcash.com/collaborators",
        },
      ],
    });
  },
  components: {
    TableCtv,
    TableRule,
    TableCtvHistory,
  },
  data() {
    return {
      isLoggedIn: false,
      referralLink: "",
      username: "",
    };
  },
  async created() {
    this.checkLoginStatus();
    if (this.isLoggedIn) {
      await this.setReferralLink();
      
    }
  },
  methods: {
    checkLoginStatus() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.isLoggedIn = !!user;

      // Lấy tham số `ref` từ URL
      const urlParams = new URLSearchParams(window.location.search);
      const ref = urlParams.get("ref");

      // In ra giá trị `ref` để kiểm tra
      console.log("Referral code from URL:", ref);

      if (ref) {
        // Lưu `ref` vào `localStorage`
        localStorage.setItem("referral_ref", ref);
        console.log("Saved referral to localStorage:", ref);
      } else {
        console.log("No referral code found in URL.");
      }
    },
    async setReferralLink() {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const user = await getCurrentUser(token);
          const username = user.data.username;
          this.referralLink = `https://luckygcash.com/?ref=${username}`;
          console.log("Generated referral link:", this.referralLink); // Kiểm tra liên kết giới thiệu đã tạo
        } catch (error) {
          console.error("Error setting referral link:", error);
        }
      }
    },

    copyRewardContent(content) {
      navigator.clipboard.writeText(content).then(() => {
        EventBus.emit("showCopyNotification", `Kopya`);
      });
    },
  },
};
</script>

<style>
.table-center th,
.table-center td {
  text-align: center;
}
.small-notes {
  color: #c7c7c7;
  font-size: 12px;
}
.link-ctv {
  max-width: max-content;
  margin: auto auto 1rem;
  background: #373737;
  border-radius: 8px;
  padding: 0 10px;
  white-space: normal; /* Đảm bảo nội dung xuống dòng khi quá dài */
  overflow-wrap: anywhere; /* Thêm tính năng ngắt dòng ở bất kỳ đâu nếu cần */
}

@media screen and (max-width: 540px) {
  .link-ctv {
    font-size: 11px;
    word-break: break-word; /* Duy trì ngắt dòng trên thiết bị nhỏ */
  }
}
</style>