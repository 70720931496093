<template>
  <div class="col-12">
    <div class="dashbox">
      <div class="dashbox-title"><h1>Vip</h1></div>
      <div v-if="!isLoggedIn" class="text-center">
        <router-link to="/log-in">MAG-LOGIN </router-link> PARA IPAKITA ANG
        IYONG VIP
      </div>
      <div v-if="isLoggedIn" class="text-center">
        Ang iyong kasalukuyang VIP ay: {{ vipLevel }}
      </div>
      <div v-if="isLoggedIn && vipLevel === 0" class="text-center">
        VIP Upgrade Kundisyon 1: Abutin ang 20 php at
        <router-link to="/bank">i-link ang GCash wallet</router-link>
      </div>
      <div class="col-12 col-xl-7 mx-auto">
        <div class="dashbox p-0 pb-2">
          <table class="game-table table-center">
            <thead>
              <th>Level</th>
              <th>Landmark</th>
              <th>Benepisyo</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip1.png"
                    alt=""
                  />
                </td>
                <td>20 php <br /></td>
                <td>
                  <!-- I-unlock ang mga laro sa Telegram na may mas malaking logro at
                  bawiin ang mga panalo <br /> -->
                  I-unlock ang kabuuang gantimpala araw-araw na pagdalo 60 php
                  <br />
                  I-unlock ang pang-araw-araw na misyon kabuuang gantimpala 225
                  php
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip2.png"
                    alt=""
                  />
                </td>
                <td>20.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip3.png"
                    alt=""
                  />
                </td>
                <td>40.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip4.png"
                    alt=""
                  />
                </td>
                <td>60.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip5.png"
                    alt=""
                  />
                </td>
                <td>80.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip6.png"
                    alt=""
                  />
                </td>
                <td>120.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip7.png"
                    alt=""
                  />
                </td>
                <td>180.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip8.png"
                    alt=""
                  />
                </td>
                <td>250.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip9.png"
                    alt=""
                  />
                </td>
                <td>350.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
              <tr>
                <td>
                  <img
                    class="img-vip"
                    src="../../public/images/vips/vip10.png"
                    alt=""
                  />
                </td>
                <td>500.000 php</td>
                <td>Magkakaroon kami ng mga espesyal na alok para sa iyo</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import { getCurrentVipUser } from "../api/vip"; // Đảm bảo bạn đã import đúng đường dẫn

export default {
  name: "VipPage",
  setup() {
    useHead({
      title: "Vip",
      link: [
        {
          rel: "canonical",
          href: "https://luckygcash.com/vip",
        },
      ],
    });
  },
  data() {
    return {
      isLoggedIn: false,
      vipLevel: null, // Thêm thuộc tính để lưu trữ cấp độ VIP
    };
  },
  async created() {
    this.checkLoginStatus();
    if (this.isLoggedIn) {
      try {
        const token = localStorage.getItem("access_token"); // Hoặc phương thức lấy token của bạn
        const vipData = await getCurrentVipUser(token);
        this.vipLevel = vipData.data; // Giả sử API trả về một đối tượng có thuộc tính `level`
      } catch (error) {
        console.error("VIP:", error);
      }
    }
  },
  methods: {
    checkLoginStatus() {
      // Hàm kiểm tra trạng thái đăng nhập
      this.isLoggedIn = !!localStorage.getItem("access_token");
    },
  },
};
</script>


<style>
.img-vip {
  width: 50px;
}
</style>