<template>
  <div class="row">
    <div class="col-12 col-xl-6">
      <div class="dashbox">
        <div
          class="dashbox-title d-flex justify-content-around align-items-center"
        >
          <h2>Even o Odd</h2>
          <a class="scroll-video link-color" href="#video-even-o-odd"
            >Tutorial</a
          >
        </div>
        <div class="overflow-x-auto p-0">
          <table class="game-table">
            <thead>
              <tr>
                <th>GCash Wallet</th>
                <th>Huling number</th>
                <th>Ratio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="td-fl">
                  <span>09481739806</span>

                  <button
                    class="btn copy-btn"
                    @click="copyRewardContent('09481739806')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clipboard"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"
                      />
                      <path
                        d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"
                      />
                    </svg>
                  </button>
                </td>
                <td>
                  <code>0</code>
                  <code>2</code>
                  <code>4</code>
                  <code>6</code>
                  <code>8</code>
                </td>
                <td>x1.85</td>
              </tr>
              <tr>
                <td colspan="3" class="text-center game-describe">
                  Kung ang hula mo sa last number ng REF Number ay even
                  (0,2,4,6,8) pagkatapos ay itransfer mo ang iyong bet sa Gcash
                  number na ito: <span>09481739806</span>
                </td>
              </tr>
              <tr>
                <td class="td-fl">
                  <span>09564725147</span>
                  <button
                    class="btn copy-btn"
                    @click="copyRewardContent('09564725147')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clipboard"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"
                      />
                      <path
                        d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"
                      />
                    </svg>
                  </button>
                </td>
                <td>
                  <code>1</code>
                  <code>3</code>
                  <code>5</code>
                  <code>7</code>
                  <code>9</code>
                </td>
                <td>x1.85</td>
              </tr>
              <tr>
                <td colspan="3" class="text-center game-describe">
                  Kung ang hula mo sa huling number ng REF Number ay odd
                  (1,3,5,7,9) pagkatapos ay itransfer mo ang iyong bet sa Gcash
                  number na ito: <span>09564725147</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="game-describe text-center">
          <span class="game-describe text-center attention-text">
            Ang resulta ay batay sa huling number ng Reference Number mula sa
            GCash wallet
          </span>
          <br />
          - Minimum 20 ₱/bawat money transfer<br />
          - Maximum na 500 ₱/bawat money transfer <br />Tandaan: Kung ililipat
          mo ang minimum o maximum na halaga na hindi alinsunod sa game
          regulations. Ang system ay hindi magrerefund ng pera.
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-6">
      <div class="dashbox" id="video-even-o-odd">
        <div class="dashbox-title">
          <h2>Mga instruction video</h2>
        </div>
        <div class="video-game">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/RsFNlxXb6io?si=_M1eHmhQUtYDbINA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/js/eventBus";
import { getCurrentUser } from "@/api/user";

export default {
  name: "GameChanle",
  data() {
    return {
      isLoggedIn: false,
      user: null,
    };
  },
  created() {
    this.checkUserStatus();
    EventBus.on("loginSuccess", this.handleLoginSuccess);
    EventBus.on("registerSuccess", this.handleLoginSuccess);
  },
  beforeUnmount() {
    EventBus.off("loginSuccess", this.handleLoginSuccess);
    EventBus.off("registerSuccess", this.handleLoginSuccess);
  },
  methods: {
    async checkUserStatus() {
      const token = localStorage.getItem("access_token");
      if (token) {
        const user = await this.getUserFromToken(token);
        if (user) {
          this.setUser(user);
        }
      }
    },
    async getUserFromToken(token) {
      try {
        const response = await getCurrentUser(token);
        if (response && response.data) {
          return response.data;
        } else {
          this.logout();
          return null;
        }
      } catch (error) {
        console.error("Failed to fetch user from token:", error);
        this.logout();
        return null;
      }
    },
    async handleLoginSuccess() {
      const token = localStorage.getItem("access_token");
      if (token) {
        const user = await this.getUserFromToken(token);
        if (user) {
          this.setUser(user);
        }
      }
    },
    setUser(user) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    copyRewardContent(content) {
      // Sử dụng một khóa chung cho tất cả các nút sao chép
      const lastCopyEventTime = localStorage.getItem("lastCopyEventTime");
      const now = new Date().getTime();
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;

      // Kiểm tra nếu sự kiện chưa xảy ra hoặc đã hơn 30 ngày
      if (
        !lastCopyEventTime ||
        now - lastCopyEventTime > thirtyDaysInMilliseconds
      ) {
        navigator.clipboard.writeText(content).then(() => {
          EventBus.emit("showCopyNotification");

          // Gửi sự kiện Facebook Pixel
          if (typeof fbq === "function") {
            window.fbq("track", "CopyLucky", {
              walletNumber: content,
            });
          }

          // Lưu dấu thời gian kích hoạt sự kiện
          localStorage.setItem("lastCopyEventTime", now.toString());
        });
      } else {
        // Nếu sự kiện đã được ghi nhận trong vòng 30 ngày, chỉ sao chép nội dung
        navigator.clipboard.writeText(content).then(() => {
          EventBus.emit("showCopyNotification");
        });
      }
    },
  },
};
</script>
