import * as yup from "yup";

export const registerSchema = yup.object().shape({
  username: yup
    .string()
    .matches(
      /^[a-zA-Z][a-zA-Z0-9]*$/,
      "Username must start with a letter and contain no spaces"
    )
    .min(6, "Username must be at least 6 characters long")
    .required("Username is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  captchaCode: yup.string().required("Captcha code is required"),
});
