<template>
<div class="col-lg-6 mx-auto">
    <div class="dashbox p-5">
        <div class="dashbox-img">
            <a href="/">
                <img src="../../public/images/logo.png" alt="" width="200px" />
            </a>
        </div>

        <form @submit.prevent="register" class="text-center w-100 position-relative">
            <div class="form-group">
                <input @input="validateInput('username')" type="text" id="username" v-model="username" class="form-control" placeholder="Enter account name" />
                <div v-if="errors.username" class="error-message">
                    {{ errors.username }}
                </div>
            </div>
            <div class="form-group">
                <input @input="validateInput('password')" type="password" id="password" v-model="password" class="form-control" placeholder="Enter password" />
                <div v-if="errors.password" class="error-message">
                    {{ errors.password }}
                </div>
            </div>
            <div class="form-group">
                <input @input="validateInput('confirmPassword')" type="password" id="confirmPassword" v-model="confirmPassword" class="form-control" placeholder="Confirm password" />
                <div v-if="errors.confirmPassword" class="error-message">
                    {{ errors.confirmPassword }}
                </div>
            </div>

            <!-- Captcha Image -->
            <div class="form-group form-captcha">
                <img :src="captchaImage" alt="Captcha Image" class="img-captcha" />
                <button type="button" @click="loadCaptcha">
                    <i class="fa-solid fa-rotate" style="color: #ffffff"></i>
                </button>
            </div>
            <div class="form-group">
                <input type="text" id="captchaCode" v-model="captchaCode" class="form-control" placeholder="Enter captcha code" />
                <div v-if="errors.captchaCode" class="error-message">
                    {{ errors.captchaCode }}
                </div>
            </div>

            <div>
                <button class="w-100 bg-gradient-button" type="submit">
                    <span>Register</span>
                </button>
            </div>

            <div>
                Already have an account?
                <a class="link-color" href="/log-in">Log-in</a>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    registerUser
} from "@/api/register";
import {
    getCaptchaId,
    getCaptchaImage
} from "@/api/captcha";
import EventBus from "@/js/eventBus";
import {
    registerSchema
} from "@/schemas/registerSchema";
import {
    useHead
} from "@vueuse/head";
export default {
    name: "RegisterForm",
    setup() {
        useHead({
            title: "Register",
        });
    },
    data() {
        return {
            username: "",
            password: "",
            confirmPassword: "",
            captchaCode: "",
            captchaId: "",
            captchaImage: "",
            errors: {
                username: "",
                password: "",
                confirmPassword: "",
                captchaCode: "",
            },
            error: "",
            // utmInfo: this.getUtmInfo(), // Lấy thông tin UTM
        };
    },
    async created() {
        await this.loadCaptcha();
    },
    mounted() {
        const params = new URLSearchParams(window.location.search);
        let refValue = params.get("ref");
        if (refValue ?.length > 0) {
            localStorage.setItem("ref", refValue);
            console.log(refValue);
        }
    },
    methods: {
        async loadCaptcha() {
            try {
                // Lấy Captcha ID
                const captchaResponse = await getCaptchaId();
                this.captchaId = captchaResponse.data.captcha_id;
                // Lấy hình ảnh Captcha
                const captchaImageBlob = await getCaptchaImage(this.captchaId);
                this.captchaImage = URL.createObjectURL(captchaImageBlob);
            } catch (error) {
                this.error = "Nabigo ang pag-download ng Captcha: " + error.message;
            }
        },
        validateInput() {
            let value = this.username;
            // Chuyển đổi thành chữ thường
            value = value.toLowerCase();
            if (value.length > 0 && !/^[a-z]/.test(value[0])) {
                this.errors.username = "Username must start with a letter";
            } else if (/\s/.test(value)) {
                this.errors.username = "Username must not contain spaces";
            } else {
                this.errors.username = "";
            }
            this.username = value;
        },
        async register() {
            this.clearErrors();
            try {
                await registerSchema.validate({
                    username: this.username,
                    password: this.password,
                    confirmPassword: this.confirmPassword,
                    captchaCode: this.captchaCode,
                }, {
                    abortEarly: false
                });
                const urlParams = new URLSearchParams(window.location.search);
                let utm = {
                    utm_source: "web",
                    utm_medium: "referral",
                    utm_campaign: urlParams.get("utm_campaign"),
                    utm_term: urlParams.get("utm_term"),
                    utm_content: urlParams.get("ref") || localStorage.getItem("ref"),
                };
                if (urlParams.get("ref") ?.length > 0) {
                    utm.utm_source = urlParams.get("ref");
                    utm.utm_medium = "referral";
                }
                let refValue = localStorage.getItem("ref");
                if (utm.utm_medium !== "referral" && refValue ?.length > 0) {
                    utm.utm_source = refValue;
                    utm.utm_medium = "referral";
                }
                try {
                    const response = await registerUser(
                        this.username,
                        this.password,
                        this.captchaId,
                        this.captchaCode,
                        utm
                    );
                    if (response.success) {
                        const loginToken = response.data;
                        localStorage.setItem("access_token", loginToken.access_token);
                        localStorage.setItem("expires_at", loginToken.expires_at);
                        localStorage.setItem("token_type", loginToken.token_type);
                        localStorage.setItem("registrationSuccess", "true");
                        EventBus.emit("registerSuccess", loginToken);
                        localStorage.removeItem("referral_ref");
                        this.$router.push("/play");
                    } else {
                        this.handleServerErrors(response.errorDetail);
                        await this.loadCaptcha();
                        this.captchaCode = ""; // Reset captcha code
                    }
                } catch (registerError) {
                    this.error =
                        registerError.message || "An error occurred, please try again.";
                    await this.loadCaptcha();
                    this.captchaCode = ""; // Reset captcha code
                }
            } catch (validationError) {
                validationError.inner.forEach((err) => {
                    this.errors[err.path] = err.message;
                });
            }
        },
        handleServerErrors(errorDetail) {
            if (errorDetail.includes("captcha")) {
                this.errors.captchaCode = errorDetail;
            } else if (errorDetail.includes("Username already exists")) {
                this.errors.username = errorDetail;
            } else if (errorDetail.includes("password")) {
                this.errors.password = errorDetail;
            } else {
                this.error = errorDetail;
            }
        },
        clearErrors() {
            this.errors = {
                username: "",
                password: "",
                confirmPassword: "",
                captchaCode: "",
            };
        },
    },
};
</script>

<style scoped>
</style>
