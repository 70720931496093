<template>
  <div id="app" class="container-fluid">
    <CopyNotification />
    <Header
      :isSidebarOpen="isSidebarVisible"
      @toggle-sidebar="updateSidebarVisibility"
    />
    <SidebarMenu
      :isVisible="isSidebarVisible"
      @update:isVisible="updateSidebarVisibility"
      @toggle-sidebar="updateSidebarVisibility"
    />
    <MainContent>
      <router-view />
    </MainContent>
    <div
      v-if="isSidebarVisible"
      class="sidebar-overlay"
      @click="closeSidebar"
    ></div>
  </div>
</template>

<script>
import SidebarMenu from "./components/SidebarMenu.vue";
import MainContent from "./components/MainContent.vue";
import Header from "./components/HeaderMobile.vue";
import CopyNotification from "./components/Popup/CopyNotification.vue";

export default {
  name: "App",
  components: {
    SidebarMenu,
    MainContent,
    Header,
    CopyNotification,
  },
  data() {
    return {
      isSidebarVisible: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    closeSidebar() {
      this.isSidebarVisible = false;
    },
    updateSidebarVisibility(visible) {
      this.isSidebarVisible = visible;
    },
  },
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  position: relative;
}
@media (max-width: 1199px) {
  #app {
    padding-top: 60px; /* Adjust according to header height */
  }
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 98;
}
</style>
