<template>
  <div v-if="visible" class="notification">
    Kopya
  </div>
</template>
  
  <script>
import EventBus from "@/js/eventBus";

export default {
  name: "CopyNotification",
  data() {
    return {
      visible: false,
    };
  },
  created() {
    EventBus.on("showCopyNotification", this.showNotification);
  },
  methods: {
    showNotification() {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, 1000);
    },
  },
  beforeUnmount() {
    EventBus.off("showCopyNotification", this.showNotification);
  },
};
</script>
  
<style>
.notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: cadetblue;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 999;
}
</style>