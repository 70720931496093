import axios from "axios";

// Sử dụng biến môi trường cho URL
const apiV1Url = process.env.VUE_APP_API_V1_URL;
const apiV2Url = process.env.VUE_APP_API_V2_URL;

export const apiClientV1 = axios.create({
  baseURL: apiV1Url,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiClientV2 = axios.create({
  baseURL: apiV2Url,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to add the authorization token
const addAuthInterceptor = (client) => {
  client.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

addAuthInterceptor(apiClientV1);
addAuthInterceptor(apiClientV2);