// src/schemas/loginSchema.js
import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  username: yup.string()
    .required("Username is required"),
  password: yup.string()
    .required("Password is required"),
  captchaCode: yup.string()
    .required("Captcha code is required"),
});
