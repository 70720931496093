<template>
  <div class="col-12">
    <div class="dashbox p-0">
      <div class="dashbox-title">
        <h2>Rewards para sa collaborators</h2>
      </div>
      <div class="overflow-x-auto p-0">
        <table class="game-table table-center">
          <thead>
            <tr>
              <th>Bilang ng mga taong inimbitahan</th>
              <th>Bilang ng mga kwalipikadong tao</th>
              <th>Ang dami na pera na iyong natanggap</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ referralData.invite }}</td>
              <td>{{ referralData.valid_invite }}</td>
              <td>{{ referralData.estimate_reward }}</td>
            </tr>
          </tbody>
        </table>
        <p v-if="!referralData">Walang maipakitang datos</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getReferralStatus } from "@/api/referral";

export default {
  name: "TableCtv",
  data() {
    return {
      referralData: [],
    };
  },
  async created() {
    await this.fetchReferralData();
  },
  methods: {
    async fetchReferralData() {
      try {
        const response = await getReferralStatus();
        this.referralData = response.data;
      } catch (error) {
        console.error("Failed to fetch referral data:", error);
      }
    },
  },
};
</script>

<style>

</style>
