<template>
  <div class="col-12 col-xl-6 mx-auto">
    <div class="dashbox p-0">
      <div class="dashbox-title"><h2>Kasaysayan ng gantimpala</h2></div>
      <table class="game-table table-center">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in paginatedTransactions" :key="transaction.id">
            <td>{{ transaction.amount }}</td>
            <td>{{ formatDate(transaction.created_at) }}</td>
            <td>{{ transaction.status }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-controls">
        <button @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>{{ currentPage }} / {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getReferralHistory } from "@/api/referral";

export default {
  name: "tableCtvHistory",
  data() {
    return {
      transactions: [],
      currentPage: 1,
      itemsPerPage: 7,
    };
  },
  async created() {
    try {
      const response = await getReferralHistory();
      this.transactions = response.data.transactions;
    } catch (error) {
      console.error(error.message);
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.transactions.length / this.itemsPerPage);
    },
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.transactions.slice(start, end);
    },
  },
  methods: {
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style scoped>
.game-table {
  width: 100%;
  border-collapse: collapse;
}
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.pagination-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}
.pagination-controls span {
  margin: 0 10px;
}
</style>
