// @/api/missionApi.js
import { apiClientV1 } from "./apiClient";

// Hàm để lấy thông tin nhiệm vụ hiện tại của người dùng
export const fetchMissionData = async () => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    console.log("No token found, please log in again.");
  }
  try {
    const response = await apiClientV1.get("/mission", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch mission reward info:", error);
    throw error;
  }
};

// Trong hàm claimReward
export const claimReward = async (level) => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    console.log("No token found, please log in again.");
  }
  try {
    const response = await apiClientV1.post(`/mission/claim/${level}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Failed to claim reward:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
