import { apiClientV1 } from "./apiClient";

export const getCheckinStatus = async () => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get("/checkin", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching checkin status:", error);
    throw error;
  }
};

export const doCheckin = async () => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await apiClientV1.post(
      "/checkin",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during check-in:", error);
    throw error;
  }
};
