import { apiClientV1 } from "./apiClient";

// Hàm để lấy thông tin người dùng VIP hiện tại từ token
export const getCurrentVipUser = async (token) => {
  try {
    const response = await apiClientV1.get("/user-wallets/vip", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
