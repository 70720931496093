<template>
  <div class="home-page">
    <h1 class="d-none">Play Game</h1>
    <div class="row">
      <div class="col-12" v-if="currentTab === 'even-o-odd'">
        <GameChanle />
      </div>
      <div class="col-12" v-if="currentTab === 'maliit-malaki'">
        <GameTaixiu />
      </div>
      <div
        class="col-12 overflow-x-auto"
        v-if="currentTab === 'hulaan-ang-number'"
      >
        <GameDoanso />
      </div>
      <div class="col-12 overflow-x-auto" v-if="currentTab === 'gametele'">
        <GameTele />
      </div>
      <TableThongke />
      <TableSoicau />
      <TablePlayHistory />

      <!-- Thêm popup vào đây -->
      <!-- <WalletLinkPopup /> -->
      <!-- <LogAndRegPopup /> -->
      <RegisterPopup />
    </div>
  </div>
</template>

<script>
import TableThongke from "@/components/tableThongke.vue";
import GameChanle from "@/components/GameChanle.vue";
import GameDoanso from "@/components/GameDoanso.vue";
import GameTele from "@/components/GameTele.vue";
import GameTaixiu from "@/components/GameTaiXiu.vue";
import TableSoicau from "@/components/tableSoicau.vue";
import TablePlayHistory from "@/components/tablePlayHistory.vue";
// import WalletLinkPopup from "@/components/Popup/WalletLinkPopup.vue";
// import { getLinkedPaymentMethod } from "@/api/payment";
import RegisterPopup from "@/components/Popup/RegisterPopup.vue";
// import EventBus from "@/js/eventBus";
// import LogAndRegPopup from "@/components/LogAndRegPopup.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "PlayGame",
  setup() {
    useHead({
      title: "Play Game",
      link: [
        {
          rel: "canonical",
          href: "https://luckygcash.com/play",
        },
      ],
    });
  },
  data() {
    return {
      paymentMethod: null,
    };
  },
  components: {
    GameChanle,
    GameTaixiu,
    GameDoanso,
    GameTele,
    TableThongke,
    TableSoicau,
    TablePlayHistory,
    RegisterPopup,
    // WalletLinkPopup,
    // LogAndRegPopup,
  },
  computed: {
    currentTab() {
      return this.$route.query.tab || "even-o-odd";
    },
  },
  created() {
    // this.checkLoginStatus();
    // this.checkPaymentMethod();
  },
  methods: {
    // checkLoginStatus() {
    //   const accessToken = localStorage.getItem("access_token");
    //   console.log(accessToken);
    //   if (!accessToken) {
    //     console.log("No access_token found, showing login/register popup.");
    //     EventBus.emit("showLogAndRegPopup");
    //   } else {
    //     this.checkPaymentMethod(); // Chỉ kiểm tra payment method nếu đã có token
    //   }
    // },
    // async checkPaymentMethod() {
    //   try {
    //     const data = await getLinkedPaymentMethod();
    //     this.paymentMethod = data.data;
    //     if (!this.paymentMethod || !this.paymentMethod.wallet_id) {
    //       EventBus.emit("showWalletLinkPopup");
    //     }
    //   } catch (error) {
    //     console.error("Error checking payment method:", error);
    //   }
    // },
  },
};
</script>

<style scoped></style>
