<template>
  <div class="col-12 col-xl-6">
    <div class="dashbox">
      <div class="dashbox-title"><h2>Istatistika</h2></div>
      <div class="filter-controls">
        <label for="filter">Bilang ng mga tugma:</label>
        <select class="number-filter" v-model="filter" @change="fetchData">
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="1000">1000</option>
        </select>
      </div>
      <div class="overflow-x-auto">
        <table>
          <thead>
            <tr class="tr-button">
              <th>
                <button @click="toTabUser">Ako lang</button>
              </th>
              <th>
                <button @click="toTabAllUser">Lahat ng manlalaro</button>
              </th>
            </tr>
            <tr>
              <th>No.</th>
              <th>Ang bilang ng mga pangyayari</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(count, index) in userCount" :key="index">
              <td>{{ index }}</td>
              <td>{{ count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

  
<script>
import {
  getGamePlaysUserStatsMethod,
  getGamePlaysStatsMethod,
} from "@/api/gamePlay.js";

export default {
  name: "TableThongke",
  data() {
    return {
      statistics: {
        user: {
          count: [],
          detail: [],
        },
        allUsers: {
          count: [],
          detail: [],
        },
      },
      state: 0,
      filter: 20, // Mặc định là 20
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    userCount() {
      return this.state == 0
        ? this.statistics.user.count || []
        : this.statistics.allUsers.count || [];
    },
  },
  methods: {
    async fetchData() {
      let u = await getGamePlaysUserStatsMethod(this.filter);
      let g = await getGamePlaysStatsMethod(this.filter);
      if (u && u.success) {
        this.statistics.user = u.data;
      }
      if (g && g.success) {
        this.statistics.allUsers = g.data;
      }
    },
    toTabUser() {
      this.state = 0;
      this.fetchData();
    },
    toTabAllUser() {
      this.state = 1;
      this.fetchData();
    },
  },
};
</script>

  
  <style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  width: 50%;
}
.tr-button th {
  padding: 3px;
}
button {
  width: 100%;
  cursor: pointer;
  background-color: white;
  color: black;
}

</style>
  