<!-- src/views/ErrorPage.vue -->
<template>
  <div class="error-page">
    <h1>Oops!</h1>
    <p>Nagkaroon ng error habang nilo-load ang page. Subukang muli mamaya.</p>
    
  </div>
</template> 
  
  <script>
export default {
  name: "ErrorPage",
};
</script>
  
  <style>
.error-page {
  text-align: center;
  margin-top: 50px;
}
</style>
  