<template>
  <div class="col-12">
    <div class="dashbox">
      <div class="dashbox-title">
        <h1>
          Tuklasin ang Lucky GCash - GCash Minigame: Subukan ang Iyong Swerte sa
          Pagpili ng Huling Digit ng Transaksyon
        </h1>
      </div>
      <div class="content">
        <div class="video-game text-center">
          <div class="video-container">
            <iframe src="https://www.youtube.com/embed/w37xRySGXLI?si=vDY-7efWzGDDyLtg" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>

        <p>
          Damhin ang Minigame <strong> Lucky GCash</strong> Transparent
          Entertainment, Mabilis at Reputable Rewards mula sa GCash Wallet Ang
          Minigame <strong> Lucky GCash</strong> mula sa GCash Wallet ay
          nangangako na ihahatid sa iyo ang pinakakaakit-akit at maaasahang mga
          karanasan sa entertainment. Outstanding advantages ng Minigame
          <strong> Lucky GCash</strong>
        </p>

        <h2>Ganap na transparency</h2>
        <p>
          Ang <strong> Lucky GCash</strong> ay nangangako na ang bawat resulta
          ay tinutukoy at direktang ibinalik mula sa GCash wallet system.
          Tinitiyak nito ang pagiging patas at transparency, na tumutulong sa
          iyong makatiyak na walang sinuman ang maaaring makagambala sa mga
          resulta. Ang transparency na ito ay nagdudulot ng ganap na seguridad
          sa mga manlalaro.
        </p>

        <h2>Mga napakabilis na reward</h2>
        <p>
          Ang <strong> Lucky GCash</strong> ay hindi lamang transparent ngunit
          namumukod-tangi din sa napakabilis nitong kakayahan sa pagbabayad ng
          reward. 10 seconds lamang pagkatapos manalo, ang reward ay ililipat sa
          iyong account, na magbibigay ng tuluy-tuloy at kapana-panabik na
          karanasan. Nakakatulong ito na hindi mo kailangang maghintay nang
          matagal, na nagdaragdag ng kasiyahan kapag naglalaro.
        </p>

        <h2>Prestihiyoso at legal</h2>
        <p>
          Ang <strong> Lucky GCash</strong> ay pinapatakbo sa isang ganap na
          legal at kagalang-galang na platform, na nakatuon sa pagprotekta sa
          mga karapatan ng mga manlalaro. Sa tiwala ng GCash wallet, makatitiyak
          ka kapag nakikilahok sa kawili-wili at kaakit-akit na mga minigames.
        </p>

        <p>
          3 pangunahing laro sa Lucky GCash na hindi mo dapat palampasin: Hulaan
          ang big o small:
        </p>
        <p>
          - Hulaan ang huling bilang ng Ref Number ay magiging even na numero
          (0,2,4,6,8) o isang kakaibang numero (1,3,5,7,9).
          <router-link class="link-color" to="/play?tab=even-o-odd">[Maglaro ngayon]</router-link>
        </p>
        <p>
          - Hulaan even o odd: Hulaan ang huling bilang ng Ref Number ay
          magiging malaking numero (5,6,7,8,9) o maliit na numero (0,1,2,3,4).
          <router-link class="link-color" to="/play?maliit-malaki">[Maglaro ngayon]</router-link>
        </p>
        <p>
          - Hulaan nang tama ang huling numero: Hulaan ang huling numero ng Ref
          Number ay magiging isang partikular na numero mula 0 hanggang 9.
          <router-link class="link-color" to="/play?tab=hulaan-ang-number">[Maglaro ngayon]</router-link>
        </p>

        <div v-if="!isLoggedIn" class="text-center">
          <button class="bg-gradient-button btn-register-home" @click="redirectToRegister">
            MAGREGISTER PARA SA KARANASAN NGAYON
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
  name: "HomePage",
  setup() {
    useHead({
      title:
        "Tuklasin ang Lucky GCash - GCash Minigame: Subukan ang Iyong Swerte sa Pagpili ng Huling Digit ng Transaksyon",
      meta: [
        {
          name: "description",
          content:
            "Lucky GCash - Ang pangunahing destinasyon para sa swerte, hamon, at malalaking gantimpala. Huwag palampasin ang pagkakataon na maging susunod na panalo. Sumali na ngayon!",
        },
        { name: "keywords", content: "LuckyGCash" },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "✅Lucky GCash",
                item: "https://luckygcash.com/",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "✅ MiniGame Lucky GCash",
                item: "https://luckygcash.com/play",
              },
            ],
          },
        },
      ],
    });
  },
  components: {},
  data() {
    return {
      isLoggedIn: false,
    };
  },
  created() {
    this.checkLoginStatus();
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    let refValue = params.get("ref");
    if (refValue?.length > 0) {
      localStorage.setItem("ref", refValue);
    }
  },
  methods: {
    checkLoginStatus() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.isLoggedIn = !!user;
    },
    redirectToRegister() {
      this.$router.push("/register");
    },
  },
};
</script>


<style scoped>
.btn-register-home {
  padding: 0 5px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 ratio */
  height: 0;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 25px;
  }
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>