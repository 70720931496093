<template>
  <div class="dashbox">
    <DiemDanh/>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import DiemDanh from "../components/DiemDanh.vue";


export default{
  name:"Diem_danh",
  setup() {
    useHead({
      title: "Roll Call",
      link: [
        {
          rel: "canonical",
          href: "https://luckygcash.com/roll-call",
        },
      ],
    });
  },
  components:{
    DiemDanh,
  }
}
</script>


