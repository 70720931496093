<template>
  <div class="sidebar-user-img">
    <img :src="userAvatar" alt="User Avatar" />
  </div>
</template>

<script>
import { getCurrentVipUser } from "@/api/vip";

export default {
  name: "UserAvatar",
  data() {
    return {
      userAvatar: require("../../public/images/user.png"), // Mặc định là ảnh người dùng thường
    };
  },
  async created() {
    try {
      const token = localStorage.getItem("access_token"); // Thay "yourTokenKey" bằng key token thực tế
      const response = await getCurrentVipUser(token);
      const vipLevel = response.data;
      // Kiểm tra và đặt ảnh tương ứng với level VIP
      if (vipLevel > 0 && vipLevel <= 10) {
        this.userAvatar = require(`../../public/images/vips/vip${vipLevel}.png`);
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style scoped>
.sidebar-user-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>
