<template>
  <div class="col-lg-6 mx-auto">
    <div class="dashbox p-5">
      <div class="dashbox-img">
        <a href="/">
          <img src="../../public/images/logo.png" alt="logo" width="200px" />
        </a>
      </div>

      <form
        @submit.prevent="changePassword"
        class="text-center w-100 position-relative"
      >
        <div class="form-group">
          <input
            type="password"
            id="oldPassword"
            v-model="oldPassword"
            class="form-control"
            placeholder="Enter old password"
          />
          <div v-if="errors.oldPassword" class="error-message">
            {{ errors.oldPassword }}
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            id="newPassword"
            v-model="newPassword"
            class="form-control"
            placeholder="Enter new password"
          />
          <div v-if="errors.newPassword" class="error-message">
            {{ errors.newPassword }}
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            id="confirmNewPassword"
            v-model="confirmNewPassword"
            class="form-control"
            placeholder="Confirm new password"
          />
          <div v-if="errors.confirmNewPassword" class="error-message">
            {{ errors.confirmNewPassword }}
          </div>
        </div>

        <div>
          <button class="w-100 bg-gradient-button" type="submit">
            <span>Change Password</span>
          </button>
        </div>
        <div v-if="error" class="error-message">{{ error }}</div>
        <div v-if="success" class="success-message">{{ success }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import { changePassword } from "@/api/changePass";
import { useHead } from "@vueuse/head";

export default {
  name: "ChangePasswordForm",
  setup() {
    useHead({
      title: "Palitan ANG password",
    });
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      errors: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      error: "",
      success: "",
    };
  },
  methods: {
    async changePassword() {
      this.clearErrors();
      if (this.newPassword !== this.confirmNewPassword) {
        this.errors.confirmNewPassword = "Hindi tugma ang mga password";
        return;
      }
      if (!this.oldPassword) {
        this.errors.oldPassword = "Kinakailangan ang lumang password";
      }
      if (!this.newPassword) {
        this.errors.newPassword = "Kinakailangan ang bagong password";
      }
      if (
        this.errors.oldPassword ||
        this.errors.newPassword ||
        this.errors.confirmNewPassword
      ) {
        return;
      }

      try {
        const response = await changePassword(
          this.oldPassword,
          this.newPassword
        );
        if (response.success) {
          this.success = "Matagumpay na nabago ang Password";
          this.oldPassword = "";
          this.newPassword = "";
          this.confirmNewPassword = "";
        } else {
          this.error = response.error.detail || "Nabigo ang pagpapalit ng password";
        }
      } catch (error) {
        this.error = error.message || "May pagkakamaling naganap. Pakisubukang muli";
      }
    },
    clearErrors() {
      this.errors = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      };
      this.error = "";
      this.success = "";
    },
  },
};
</script>
