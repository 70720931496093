// src/api/captcha.js
import { apiClientV1 } from "./apiClient";

// Lấy Captcha ID
export const getCaptchaId = async () => {
  try {
    const response = await apiClientV1.get("/captcha/id");
    return response.data;
  } catch (error) {
    console.log("Failed to fetch captcha ID");
  }
};

// Lấy hình ảnh Captcha
export const getCaptchaImage = async (captcha_id) => {
  try {
    const response = await apiClientV1.get(`/captcha/image`, {
      params: { id: captcha_id },
      responseType: "blob",
    });
    return response.data; // Return as blob to use as image source
  } catch (error) {
    console.log("Failed to fetch captcha image");
  }
};
