  <template>
  <div
    :class="['sidebar-menu', { 'sidebar-hidden': !isVisible }]"
    @mousedown="handleClickSidebar"
  >
    <a href="/" class="logo-sidebar" @click="closeSidebar">
      <img src="../../public/images/logo.png" alt="logo" />
    </a>
    <div class="sidebar-user" v-if="user">
      <UserAvatar />
      <div class="sidebar-user-title">
        <span>Kamusta</span>
        <p>{{ user.username }}</p>
      </div>
      <a class="sidebar-user-btn" @click="logout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          data-lucide="log-out"
          style="fill: none !important; color: #fff"
          class="lucide lucide-log-out"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
          <polyline points="16 17 21 12 16 7"></polyline>
          <line x1="21" x2="9" y1="12" y2="12"></line>
        </svg>
      </a>
    </div>
    <ul>
      <li>
        <router-link to="/" @click="closeSidebar"
          ><i class="fa-solid fa-house"></i> Home</router-link
        >
      </li>
      <li>
        <router-link to="/play" @click="closeSidebar"
          ><i class="fa-solid fa-dice-five"></i> Play Game</router-link
        >
      </li>
      <li v-if="user">
        <div @click="toggleAccountMenu" class="account-menu dropdown">
          <i class="fa-solid fa-user"></i> Account
        </div>
        <ul
          v-if="isAccountMenuOpen"
          class="account-submenu dropdown-menu"
          ref="accountSubmenu"
        >
          <li>
            <router-link to="/bank" class="dropdown-item" @click="closeSidebar">
              <i class="fa-solid fa-money-check"></i> Mga setting ng bangko
              {{ $t("bankSettings") }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/play-history"
              class="dropdown-item"
              @click="closeSidebar"
            >
              <i class="fa-solid fa-clock-rotate-left"></i> Play history
            </router-link>
          </li>
          <li>
            <router-link
              to="/telegram-link"
              class="dropdown-item"
              @click="closeSidebar"
            >
              Link ng Telegram
            </router-link>
          </li>
          <li>
            <router-link
              to="/change-password"
              class="dropdown-item"
              @click="closeSidebar"
            >
              <i class="fa-solid fa-lock"></i> Palitan ANG password
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/mission" @click="closeSidebar"
          ><i class="fa-solid fa-calendar"></i> Araw-araw na misyon</router-link
        >
      </li>
      <!-- <li>
        <router-link to="/jackpot" @click="closeSidebar">Jackpot</router-link>
      </li> -->
      <li>
        <router-link to="/vip" @click="closeSidebar"
          ><i class="fa-solid fa-star"></i> Vip</router-link
        >
      </li>
      <li>
        <router-link to="/roll-call" @click="closeSidebar"
          ><i class="fa-solid fa-calendar-days"></i> Roll Call</router-link
        >
      </li>
      <li>
        <router-link to="/collaborators" @click="closeSidebar"
          ><i class="fa-solid fa-user-group"></i> Maging
          collaborator</router-link
        >
      </li>
      <li v-if="!user">
        <router-link to="/log-in" @click="closeSidebar"
          ><i class="fa-solid fa-arrow-right-to-bracket"></i>
          Log-in</router-link
        >
      </li>
      <li v-if="!user">
        <router-link to="/register" @click="closeSidebar"
          ><i class="fa-solid fa-user-plus"></i> Register</router-link
        >
      </li>
      <li>
        <a href="https://t.me/luckygcash" target="_blank" @click="closeSidebar"
          ><i class="fa-solid fa-comment"></i> Chat with support</a
        >
      </li>
      <li>
        <a
          href="https://t.me/+PwBYFHAHg3g3OTk1"
          target="_blank"
          @click="closeSidebar"
          ><i class="fa-solid fa-comments"></i> Pamayanan ng manlalaro</a
        >
      </li>
      <!-- <div class="language-switcher">
        <button @click="changeLanguage('en')">English</button>
        <button @click="changeLanguage('ph')">Filipino</button>
      </div> -->
    </ul>
    <div class="sidebar__copyright">
      <b>LuckyGCash © 2024</b>
    </div>
  </div>
</template>

<script>
import EventBus from "@/js/eventBus";
import { getCurrentUser } from "@/api/user";
import UserAvatar from "./UserAvatar.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "SidebarMenu",
  setup() {
    const { locale } = useI18n();
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    return {
      changeLanguage,
    };
  },
  components: {
    UserAvatar,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null,
      isAccountMenuOpen: false,
    };
  },
  created() {
    this.checkUserStatus();
    EventBus.on("loginSuccess", this.handleLoginSuccess);
    EventBus.on("registerSuccess", this.handleLoginSuccess);
    document.addEventListener("mousedown", this.handleClickOutside);
  },
  beforeUnmount() {
    EventBus.off("loginSuccess", this.handleLoginSuccess);
    EventBus.off("registerSuccess", this.handleLoginSuccess);
    document.removeEventListener("mousedown", this.handleClickOutside);
  },
  methods: {
    async checkUserStatus() {
      const token = localStorage.getItem("access_token");
      if (token) {
        const user = await this.getUserFromToken(token);
        if (user) {
          this.setUser(user);
        }
      }
    },
    async getUserFromToken(token) {
      try {
        const response = await getCurrentUser(token);
        if (response && response.data) {
          return response.data;
        } else {
          this.logout();
          return null;
        }
      } catch (error) {
        console.error("Failed to fetch user from token:", error);
        this.logout();
        return null;
      }
    },
    async handleLoginSuccess() {
      const token = localStorage.getItem("access_token");
      if (token) {
        const user = await this.getUserFromToken(token);
        if (user) {
          this.setUser(user);
        }
      }
    },
    setUser(user) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    toggleAccountMenu() {
      this.isAccountMenuOpen = !this.isAccountMenuOpen;
    },
    handleClickSidebar(event) {
      if (event.target.tagName === "A") {
        this.closeSidebar();
      }
    },
    closeSidebar() {
      this.$emit("update:isVisible", false);
      this.$emit("toggle-sidebar", false);
    },
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("token_type");
      localStorage.removeItem("user");
      this.user = null;
      this.isAccountMenuOpen = false;
      this.$router.push("/log-in");
      this.closeSidebar();
    },
    handleClickOutside(event) {
      const submenu = this.$refs.accountSubmenu;
      if (submenu && !submenu.contains(event.target)) {
        this.isAccountMenuOpen = false;
      }
    },
  },
};
</script>


  <style scoped>
.sidebar-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #28282d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 101;
  width: 280px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.sidebar-hidden {
  transform: translate3d(-100%, 0, 0);
}

@media (min-width: 1200px) {
  .sidebar-hidden {
    transform: translate3d(0, 0, 0);
  }
}

.logo-sidebar {
  display: block;
  align-items: center;
  height: 85px;
  min-height: 85px;
  padding: 0 30px;
  background-color: #28282d;
  width: 100%;
  position: relative;
}
.logo-sidebar:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  display: block;
  background-image: linear-gradient(90deg, var(--main-color) 0%, #ff5860 100%);
  box-shadow: 0 0 20px 0 rgba(255, 88, 96, 0.5);
  pointer-events: none;
}

.sidebar-user {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  width: 100%;
  background-color: #28282d;
}

.sidebar-user-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.sidebar-user-title {
  margin-left: 15px;
  color: #fff;
}
.sidebar-user-title span {
  font-size: 10px;
  color: #c7c7c7;
}
.sidebar-user-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  height: 40px;
  width: 40px;
  border-radius: 8px;
  cursor: pointer;
}

.sidebar-menu ul {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 20px 30px;
  width: 100%;
}
.sidebar-menu ul li {
  margin-bottom: 10px;
  height: 36px;
}
.sidebar-menu ul li:last-child {
  margin-bottom: 0;
}
.sidebar-menu ul li a {
  text-decoration: none;
  color: white;
}

.sidebar-menu ul li a:active,
.sidebar-menu ul li a:hover {
  color: #d5c039;
}
.logo-sidebar img {
  width: 100%;
  margin-top: 20px;
}

.account-menu {
  cursor: pointer;
}

.account-submenu li {
  margin-bottom: 10px;
}
.account-submenu li a {
  color: white;
  text-decoration: none;
}
.account-submenu li a:hover,
.account-submenu la a:active {
  color: #d5c039;
}
div.sidebar-menu.sidebar-hidden > ul > li > div,
div.sidebar-menu.sidebar-hidden > ul > li > a {
  display: flex;
  align-items: center;
}

/* Media queries for tablets */
@media (max-width: 768px) {
  .sidebar-menu {
    width: 300px;
  }

  .sidebar-menu ul {
    padding: 20px;
  }

  .sidebar-menu ul li {
    height: 30px;
  }

  .sidebar-menu ul li a {
    font-size: 14px;
  }
}
.language-switcher {
  margin-top: 10px;
}
.language-switcher button {
  margin-right: 5px;
}
</style>
