import { apiClientV1 } from "./apiClient";

// Hàm để thay đổi mật khẩu người dùng hiện tại
export const changePassword = async (oldPassword, newPassword) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await apiClientV1.put(
      "/current/password",
      {
        old_password: oldPassword,
        new_password: newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Password change failed" };
  }
};
