<template>
  <div>
    <header class="header">
      <div class="header-content">
        <a href="/" class="header-logo">
          <img src="../../public/images/logo.png" alt="Logo" />
        </a>
        <button
          class="header-btn"
          @click="toggleSidebar"
          type="button"
          :class="{ open: isSidebarOpen }"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderMobile",
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggle-sidebar", !this.isSidebarOpen);
    },
    closeSidebar() {
      this.$emit("toggle-sidebar", false);
    },
  },
};
</script>

<style scoped>
.header {
  display: none;
  z-index: 99;
}

@media (max-width: 1200px) {
  .header {
    display: block;
    position: relative;
  }
}

.header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #28282d;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
}

.header-logo img {
  height: 40px;
}

.header-btn {
  background: none;
  border: none;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.header-btn span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 4px;
  background: white;
  transition: all 0.3s ease;
}

.header-btn.open span:nth-child(1) {
  transform: rotate(45deg);
  position: absolute;
}

.header-btn.open span:nth-child(2) {
  opacity: 0;
}

.header-btn.open span:nth-child(3) {
  transform: rotate(-45deg);
  position: absolute;
}
</style>