<template>
  <div class="dashbox">
    <div class="dashbox-title">
      <h1 class="mx-auto">Araw-araw na misyon</h1>
    </div>
    <div v-if="!isLoggedIn" class="text-center">
      <router-link to="/log-in">MAG-LOGIN </router-link> O
      <router-link to="/register">MAGREGISTER</router-link> PARA MAKATANGGAP NG
      REWARDS
    </div>
    <div v-if="isLoggedIn && vipLevel === 0" class="text-center">
      <p>
        VIP Upgrade Kundisyon 1: Abutin ang 20 php at
        <router-link to="/bank">i-link ang GCash wallet</router-link>
      </p>
      <p>Kabuuang pera na nilaro ngayong araw: {{ missionData.current }}</p>
      <p>
        Tandaan: ina-update ng aming system ang kabuuang halaga ng pera na
        nilalaro bawat 5 min.
      </p>
    </div>
    <div class="col-12 col-xl-6 mx-auto">
      <div class="dashbox p-0 pb-2">
        <table class="game-table">
          <thead>
            <tr>
              <th>Landmark</th>
              <th>Reward</th>
              <th>Status</th>
              <th>Action</th>
              <!-- Thêm cột cho nút nhận thưởng -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(milestone, index) in missionData.milestones"
              :key="index"
            >
              <td>{{ milestone.level }}</td>
              <td>{{ milestone.reward }}</td>
              <td>
                <span
                  :class="{
                    'status-achieved': milestone.reached,
                    'status-not-achieved': !milestone.reached,
                  }"
                >
                  {{ milestone.reached ? "Naabot" : "Hindi naabot" }}
                </span>
              </td>
              <td>
                <button
                  class="bg-gradient-button"
                  v-if="milestone.reached && !milestone.claimed"
                  @click="claimReward(milestone.level)"
                >
                  Claim Reward
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="text-center">
      <p style="line-height: 30px">
        Ang reward ay ililipat sa Telegram, maaari mong i-link ang Telegram
        pagkatapos ay pumunta sa Telegram para matanggap ang reward.
      </p>
    </div>
    <div v-if="showRewardPopup" class="popup-overlay" @click="hideRewardPopup">
      <div class="reward-popup" @click.stop>
        <span class="close-btn" @click="hideRewardPopup">&times;</span>
        <div class="reward-popup-content" id="mission_reward">
          <h2>Notification para sa rewards</h2>
          <p>{{ rewardNotification }}</p>
          <button class="bg-gradient-button" @click="redirectToTelegramGame">
            Kumuha ng rewards at maglaro ngayon
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchMissionData, claimReward } from "@/api/mission";
import { fetchLinkInfo } from "@/api/link-tele";
import { getCurrentVipUser } from "@/api/vip"; // Import hàm kiểm tra VIP
import { useHead } from "@vueuse/head";
export default {
  name: "Nhiem_vu_hang_ngayPage",
  setup() {
    useHead({
      title: "Araw-araw na misyon",
      link: [
        {
          rel: "canonical",
          href: "https://luckygcash.com/mission",
        },
      ],
    });
  },
  data() {
    return {
      isLoggedIn: false,
      missionData: {
        current: 0,
        milestones: [],
      },
      showRewardPopup: false,
      rewardNotification: "",
      linkInfo: null,
      error: null,
      vipLevel: 0, // Thêm biến để lưu cấp độ VIP của người dùng
    };
  },
  created() {
    this.checkLoginStatus();
    if (this.isLoggedIn) {
      this.fetchMissionData();
      this.checkVipLevel(); // Kiểm tra cấp độ VIP khi component được tạo
    }
  },
  methods: {
    checkLoginStatus() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.isLoggedIn = !!user;
    },
    async checkVipLevel() {
      const token = localStorage.getItem("access_token");
      try {
        const vipData = await getCurrentVipUser(token);
        this.vipLevel = vipData.data; // Giả sử vipData có trường level
      } catch (error) {
        console.error("Error checking VIP level:", error);
        this.error = error.message;
      }
    },
    async fetchMissionData() {
      try {
        const data = await fetchMissionData();
        if (data && data.data) {
          this.missionData = data.data;
        }
      } catch (error) {
        console.error("Error fetching mission data:", error);
        this.error = error.response ? error.response.data : error.message;
      }
    },
    async claimReward(level) {
      // Kiểm tra cấp độ VIP trước khi cho phép nhận phần thưởng
      if (this.userVipLevel <= 0) {
        alert("I-upgrade ang iyong vip para magamit ang function na ito");
        return;
      }

      try {
        await claimReward(level);
        this.fetchMissionData(); // Cập nhật lại dữ liệu sau khi nhận thưởng
        await this.getLinkInfo(); // Lấy thông tin link
        this.showRewardPopup = true; // Hiển thị popup
        this.rewardNotification = `Ang reward ay nailipat na sa Telegram game!`; // Nội dung thông báo
      } catch (error) {
        console.error("Error claiming reward:", error);
        this.error = error.response ? error.response.data : error.message;
      }
    },
    async getLinkInfo() {
      try {
        const data = await fetchLinkInfo();
        if (data && data.data) {
          this.linkInfo = data.data;
        }
      } catch (error) {
        console.error("Error fetching link info:", error);
        this.error = error.response ? error.response.data : error.message;
      }
    },
    startAutoRefresh() {
      this.intervalId = setInterval(() => {
        if (this.isLoggedIn) {
          this.fetchMissionData();
        }
      }, 300000); // 5 phút = 300000ms
    },
    stopAutoRefresh() {
      clearInterval(this.intervalId);
    },
    hideRewardPopup() {
      this.showRewardPopup = false;
    },
    redirectToTelegramGame() {
      if (this.linkInfo && this.linkInfo.link_deep_link) {
        window.open(this.linkInfo.link_deep_link, "_blank");
      } else {
        alert("No Telegram game link available");
      }
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  text-align: center;
  padding: 15px;
}
.status-achieved {
  background-color: green;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}
.status-not-achieved {
  background-color: brown;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-popup {
  background-color: #2b2b31;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.reward-popup-content {
  text-align: center;
}

.reward-popup h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.reward-popup button {
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #aaaaaa;
}

.close-btn:hover {
  color: #000000;
}
</style>
