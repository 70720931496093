// src/main.js

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "bootstrap/dist/css/bootstrap.css";
import { createHead } from "@vueuse/head";
import { createI18n } from "vue-i18n";
import en from "../locales/en.json";
import ph from "../locales/ph.json";
const app = createApp(App);

const i18n = createI18n({
    legacy: false, // Sử dụng chế độ composition API
    locale: "en", // Ngôn ngữ mặc định
    messages: {
      en,
      ph,
    },
  });
  

app.use(router);
app.use(vuetify);
app.use(createHead());
app.use(i18n);
app.mount("#main");
