<template>
  <div class="row">
    <div class="dashbox-title">
      <h1 class="mx-auto">Roll Call</h1>
    </div>

    <div class="col-12">
      <div class="text-center">
        <div v-if="!isLoggedIn">
          <router-link to="/log-in">MAG-LOG IN</router-link> O
          <router-link to="/register">MAGREGISTER</router-link> PARA MAG-CHECK
          IN
        </div>
        <div v-if="isLoggedIn && vipLevel === 0" class="text-center">
          VIP Upgrade Kundisyon 1: Abutin ang 20 php at
          <router-link to="/bank">i-link ang GCash wallet</router-link>
        </div>
        <div>
          <div class="line p-0"></div>

          <div class="text-center">
            <div class="p-0" v-if="countingDownIndex !== -1">
              <p>
                Mag-check in bago matapos ang araw para makaipon ng mga streak
                ng pagdalo
              </p>
            </div>
            <div
              v-if="countingDownIndex === -1 && days.some((day) => day.checked)"
            >
              <p>Roll call ngayon</p>
            </div>
          </div>

          <div class="grid-container">
            <div
              class="box"
              v-for="(day, index) in days"
              :key="index"
              @click="handleCheckIn(index)"
              :class="{
                'checked-in': day.checked,
                highlight: index === day_number && !day.checked,
              }"
            >
              <span>Day {{ index + 1 }}</span>
              <div class="div-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="currentColor"
                  class="bi bi-coin"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z"
                  />
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                  />
                  <path
                    d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12"
                  />
                </svg>
              </div>
              <span>{{ day.reward }}</span>
            </div>
          </div>

          <div class="line p-0"></div>
        </div>
        <p style="line-height: 30px">
          Dapat laruin ng mga attendance account ang GCash game kahit isang
          beses sa isang araw
        </p>
        <p style="line-height: 30px">
          Ang reward ay ililipat sa Telegram, maaari mong i-link ang Telegram
          pagkatapos ay pumunta sa Telegram para matanggap ang reward.
        </p>
      </div>
      <RewardPopup :show="showRewardPopup" @close="hideRewardPopup" />
    </div>
  </div>
</template>

<script>
import { getCheckinStatus, doCheckin } from "../api/checkin";
import RewardPopup from "./Popup/RewardPopup.vue";
import { getCurrentVipUser } from "../api/vip"; // Import hàm kiểm tra VIP

export default {
  name: "DiemDanh",
  data() {
    return {
      isLoggedIn: false,
      days: [
        { reward: "5 php", checked: false, countdown: 0 },
        { reward: "5 php", checked: false, countdown: 0 },
        { reward: "5 php", checked: false, countdown: 0 },
        { reward: "5 php", checked: false, countdown: 0 },
        { reward: "5 php", checked: false, countdown: 0 },
        { reward: "5 php", checked: false, countdown: 0 },
        { reward: "30 php", checked: false, countdown: 0 },
      ],
      countingDownIndex: -1,
      countdownInterval: null,
      showRewardPopup: false,
      day_number: 0,
      error: null,
      vipLevel: 0, // Thêm biến để lưu cấp độ VIP của người dùng
    };
  },
  components: {
    RewardPopup,
  },
  created() {
    this.checkLoginStatus();
    if (this.isLoggedIn) {
      this.getCheckinStatus();
      this.checkVipLevel(); // Kiểm tra cấp độ VIP khi component được tạo
    }
    this.days.forEach((day, index) => {
      this.startCountdown(index);
    });
  },
  methods: {
    checkLoginStatus() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.isLoggedIn = !!user;
    },
    async checkVipLevel() {
      const token = localStorage.getItem("access_token");
      try {
        const vipData = await getCurrentVipUser(token);
        this.vipLevel = vipData.data; // Giả sử vipData có trường level
      } catch (error) {
        console.error("Error checking VIP level:", error);
        this.error = error.message;
      }
    },
    async getCheckinStatus() {
      try {
        const response = await getCheckinStatus();
        const checkinData = response.data;

        // Cập nhật số chuỗi ngày đã điểm danh
        this.day_number = checkinData.day_number;

        // Đánh dấu các ngày đã điểm danh
        this.days.forEach((day, index) => {
          if (index < this.day_number) {
            day.checked = true; // Đánh dấu các ngày đã điểm danh
          }
        });

        // Kiểm tra trạng thái điểm danh ngày hôm nay
        if (checkinData.is_checked) {
          this.days[this.day_number - 1].checked = true; // Đánh dấu ngày hiện tại là đã điểm danh
        } else {
          this.countingDownIndex = this.day_number; // Highlight ngày tiếp theo
          this.startCountdown(this.countingDownIndex);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async handleCheckIn(index) {
      // Kiểm tra cấp độ VIP trước khi cho phép check-in
      if (this.userVipLevel <= 0) {
        alert("I-upgrade ang iyong vip para magamit ang function na ito");
        return;
      }

      if (!this.days[index].checked) {
        try {
          const response = await doCheckin();
          const checkinData = response.data;
          this.day_number = checkinData.day_number;
          this.days[index].checked = checkinData.is_checked;
          clearInterval(this.countdownInterval);
          this.showRewardNotification();
          const nextDayIndex = this.findNextUncheckedDayIndex(index);
          if (nextDayIndex !== -1) {
            this.countingDownIndex = nextDayIndex;
            this.startCountdown(nextDayIndex);
          } else {
            this.countingDownIndex = -1;
          }
        } catch (error) {
          console.error(error);
          if (error.response && error.response.status === 500) {
            alert(error);
          }
        }
      }
    },
    startCountdown(index) {
      const currentTime = new Date();
      const endOfDay = new Date();
      endOfDay.setUTCHours(16, 0, 0, 0); // 0h UTC+8 tương đương 16h UTC

      if (currentTime >= endOfDay) {
        endOfDay.setUTCDate(endOfDay.getUTCDate() + 1);
      }

      const countdownSeconds = Math.floor((endOfDay - currentTime) / 1000);
      this.days[index].countdown = countdownSeconds;

      this.countdownInterval = setInterval(() => {
        this.days[index].countdown--;
        if (this.days[index].countdown <= 0) {
          clearInterval(this.countdownInterval);
          this.days[index].countdown = 0;
        }
      }, 1000);
    },
    formatCountdown(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours} giờ ${minutes} phút ${remainingSeconds} giây`;
    },
    findNextUncheckedDayIndex(currentIndex) {
      for (let i = currentIndex + 1; i < this.days.length; i++) {
        if (!this.days[i].checked) {
          return i;
        }
      }
      return -1;
    },
    showRewardNotification() {
      this.showRewardPopup = true;
    },
    hideRewardPopup() {
      this.showRewardPopup = false;
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 25px;
  justify-content: center;
}
.grid-container .box:nth-child(7) {
  grid-column: span 2;
}
@media screen and (max-width: 560px) {
  .grid-container {
    grid-template-columns: auto auto auto;
  }
  .grid-container .box:nth-child(7) {
    grid-column: span 3;
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #44af49;
  padding: 5px 15px;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.box:hover {
  background-color: #28a745;
}

.box.highlight {
  border: 2px solid #ffd700;
  animation: highlightAnimation 1s infinite;
}

@keyframes highlightAnimation {
  0% {
    border-color: #ffd700;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: #ffd700;
  }
}

.div-svg {
  width: 25px;
  height: 25px;
  padding: 0;
}

svg {
  color: yellow;
}

.checked-in,
.checked-in:hover {
  background-color: #6c757d;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-popup {
  background-color: #2b2b31;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.reward-popup-content {
  text-align: center;
}

.reward-popup h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.reward-popup button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reward-popup button:hover {
  background-color: #0056b3;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #aaaaaa;
}

.close-btn:hover {
  color: #000000;
}
</style>