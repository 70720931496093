// @/api/linkApi.js
import { apiClientV1 } from "./apiClient";

// Hàm để lấy thông tin liên kết Telegram
export const fetchLinkInfo = async () => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get("/current/link-info", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; 
  } catch (error) {
    console.error("Failed to fetch link info:", error);
    throw error;
  }
};
