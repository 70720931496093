import { apiClientV1 } from "./apiClient";

export const getGamePlaysUserStatsMethod = async (limit = 20, startAt = 0) => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get(`/game-plays/user-stats/${limit}`, {
      params: { current: startAt },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail ||
      "Failed to fetch /game-plays/user-stats method";
    console.error(errorMessage);
  }
};

export const getGamePlaysStatsMethod = async (limit = 20, startAt = 0) => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get(`/game-plays/stats/${limit}`, {
      params: { current: startAt },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail ||
      "Failed to fetch /game-plays/stats method";
    console.error(errorMessage);
  }
};
