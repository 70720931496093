<template>
  <div class="col-12 col-xl-6">
    <div class="dashbox">
      <div class="dashbox-title"><h2>Kasaysayan</h2></div>
      <div class="filter-controls">
        <label for="filter">Bilang ng mga tugma:</label>
        <select class="number-filter" v-model="filter" @change="fetchData">
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div class="overflow-x-auto">
        <table class="history-table">
          <thead>
            <tr class="tr-button">
              <th colspan="5">
                <button @click="toTabUser">Ako lang</button>
              </th>
              <th colspan="5">
                <button @click="toTabAllUser">Lahat ng manlalaro</button>
              </th>
            </tr>
           
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in detail" :key="rowIndex">
              <td v-for="(num, colIndex) in row" :key="colIndex">{{ num }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getGamePlaysUserStatsMethod,
  getGamePlaysStatsMethod,
} from "@/api/gamePlay.js";

export default {
  name: "TableSoicau",
  data() {
    return {
      statistics: {
        user: {
          count: [],
          detail: [],
        },
        allUsers: {
          count: [],
          detail: [],
        },
      },
      state: 0,
      filter: 20, // Mặc định là 10
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    detail() {
      let v =
        this.state == 0
          ? this.statistics.user.detail || []
          : this.statistics.allUsers.detail || [];
      let r = [];
      for (let i = 0; i < v.length; i += 10) {
        let chunk = v.slice(i, i + 10);
        if (chunk.length < 10) {
          chunk = chunk.concat(new Array(10 - chunk.length).fill("-"));
        }
        r.push(chunk);
      }
      return r;
    },
  },
  methods: {
    async fetchData() {
      let u = await getGamePlaysUserStatsMethod(this.filter);
      let g = await getGamePlaysStatsMethod(this.filter);
      if (u && u.success) {
        this.statistics.user = u.data;
      }
      if (g && g.success) {
        this.statistics.allUsers = g.data;
      }
    },
    toTabUser() {
      this.state = 0;
      this.fetchData();
    },
    toTabAllUser() {
      this.state = 1;
      this.fetchData();
    },
  },
};
</script>


<style scoped>
table {
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.button-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.tr-button th {
  padding: 3px;
}
button {
  width: 100%;
  cursor: pointer;
  background-color: white;
  color: black;
}
</style>
