<template>
  <div class="col-12">
    <div class="dashbox">
      <h1 class="d-none">JACKPOT</h1>
      <div class="text-center bg-jackpot p-0">
        <div class="content">
          <div class="gif p-0">
            <video width="100%" loop autoplay muted playsinline>
              <source src="../../public/images/JACKPOT.mp4" type="video/mp4" />
            </video>
          </div>
          <p>Ang pera sa Jackpot ay naghihintay sa iyo ngayon</p>

          <p class="jackpot-number">
            <span
              v-for="(digit, index) in formattedJackpotAmount"
              :key="index"
              class="digit"
              >{{ digit }}</span
            >PHP
          </p>
          <p>
            Ang Jackpot ay gift na nakareserve para sa masusuwerteng manlalaro
          </p>
          <p>
            Magkakaroon ka ng pagkakataong manalo ng Jackpot kapag naglaro ka sa
            aming telegram dice game
          </p>
          <p>
            Maaari kang maglaro ng dice game dito
            <a
              :href="linkInfo ? linkInfo.link_deep_link : '#'"
              class="link-color"
              >Telegram</a
            >
          </p>
          <p>Good luck at makakuha ng malaking Jackpot</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLinkInfo } from "@/api/link-tele";
export default {
  name: "JackpotPage",
  data() {
    return {
      linkInfo: null,
      error: null,
      jackpotAmount: "10000",
    };
  },
  methods: {
    async getLinkInfo() {
      try {
        const data = await fetchLinkInfo();
        if (data && data.data) {
          this.linkInfo = data.data;
        }
      } catch (error) {
        console.error("Error fetching link info:", error);
        this.error = error.response ? error.response.data : error.message;
      }
    },
  },
  mounted() {
    this.getLinkInfo();
  },
  computed: {
    jackpotAmountDigits() {
      // Loại bỏ dấu phẩy và tách từng chữ số
      return this.jackpotAmount.replace(/,/g, "").split("");
    },
    formattedJackpotAmount() {
      return this.jackpotAmount.split("");
    },
  },
};
</script>

<style scoped>
.bg-jackpot {
  background-image: url(../../public/images/5.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 100vh;
  border-radius: 5px;
  position: relative;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right bottom, #6a5af9, #f62682);
}
@media screen and (max-width: 560px) {
  .bg-jackpot {
    background-image: none;
    height: auto;
  }
  .gif {
    display: block !important;
  }
  .jackpot-number {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 400px) {
  .jackpot-number {
    font-size: 26px !important;
  }
}
@media screen and (min-width: 560px) {
  .content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20%;
  }
  .jackpot-number {
    font-size: 50px;
  }
}

.jackpot-number {
  text-shadow: 0px 0px 5px yellow;
  width: max-content;
  padding: 10px 0;
  margin: auto;
}
.digit {
  border: 3px solid rgb(82, 180, 251); /* Đường viền cho mỗi số */
  border-radius: 8px;
  padding: 0 5px; /* Điều chỉnh khoảng cách nếu cần thiết */
  margin: 0 5px;
}
.gif {
  display: none;
}
</style>