<template>
  <div class="main-content">
    <HorizontalMenu />
    <slot></slot>
    <CtaSetup />
  </div>
</template>

<script>
import CtaSetup from "./CtaSetup.vue";
import HorizontalMenu from "./HorizontalMenu.vue";
export default {
  name: "MainContent",
  components: {
    HorizontalMenu,
    CtaSetup,
  },
};
</script>

<style scoped>
.main-content {
  flex: 1;
  padding: 0 20px 50px 20px;
}
@media (max-width: 1199px) {
  .main-content {
    padding: 25px 0 40px;
  }
}
@media (min-width: 1200px) {
  .main-content {
    padding: 0 15px 40px 295px;
    margin-top: 0;
  }
}
</style>
