<template>
  <div class="row">

    <div class="col-12">
      <div class="dashbox">
        <div class="dashbox-title">
          <h2>Game Telegram</h2>
        </div>
        <div style="">
          <div class="p-0">
            <h3>About game</h3>
            <div class="video-game">
              <div class="video-container"><iframe src="https://www.youtube.com/embed/2lkbDUW1zSk?si=yakThFK4zUzL0t1B"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

            </div>
            <p>
              - Maligayang pagdating sa aming LuckyGcash game world. <br>
              - Ito ay isang kumbinasyon ng mga laro sa casino sa Telegram application, hindi na kailangang mag-install
              ng iba pang mga application. <br />
              - Narito ang mga laro ay ganap na transparent, nang walang interbensyon ng developer, mataas na rate ng
              panalo, malalaking premyo, mabilis na pag-withdraw. <br />
              - Bilang karagdagan sa mga premyo sa mga laro, mayroon ka ring pagkakataong subukan ang iyong suwerte sa
              napakalaking jackpot prize<br />
              - Gumagawa din kami ng maraming bagong feature para maranasan mo: feature na hula sa resulta - pataasin
              ang
              rate ng panalo, mini game para makatanggap ng mga reward code.....
            </p>

          </div>
          <div class="p-0">
            <h3 class="text-center">Getting Started</h3>
            <p>Go to Luckygcash</p>
            <p><strong>Step 1</strong></p>
            <p>- Bisitahin ang <a href="https://t.me/lucky_gcash_bot?start" target="_blank"
                rel="noopener noreferrer">https://t.me/lucky_gcash_bot?start</a> sa iyong device (mobile o
              desktop/laptop)</p>
            <p><strong>Step 2</strong></p>
            <p>- i-click ang <strong>Start</strong></p>
          </div>

          <div class="p-0">

            <h3 class="text-center">Feature</h3>
            <h4>Palitan ang Pangalan
            </h4>
            <p>Kapag pumunta ka sa amin, binibigyan ang bawat user ng account na may default na user name. Ito ay
              isang
              tampok na tumutulong sa iyong baguhin ang impormasyon ng user name sa pangalan na gusto mo ( ⚠️tandaan na
              maaari mo lamang itong baguhin nang isang beses, piliin ang pangalan na pinakagusto mo).</p>
            <p><strong>Paano magpalit ng pangalan:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- I-click piliin ang pindutang " Palitan ang Pangalan "</p>
            <p><strong>Step 2</strong></p>
            <p>- Ilagay ang iyong " user name" at isumite. Matagumpay mong napalitan ang iyong pangalan</p>
            <h4>Affiliate</h4>
            <p>/Affiliate (referral): ay ang feature na "Mag-imbita ng mga kaibigan" na binuo ng luckyGcash. Gamit ang
              aming bagong feature na Mag-imbita ng Mga Kaibigan, hindi mo lang masisiyahan ang laro nang magkasama,
              ngunit makakatanggap ka rin ng mataas na komisyon para sa bawat kaibigan na sasali!</p>
            <p><strong> Paano ito gumagana:</strong></p>
            <p>- Anyayahan ang mga kaibigan na maglaro at magsimulang kumita!</p>
            <p>- Ang iyong mga reward sa komisyon ay maiipon kapag ang mga taong inimbitahan mo ay sumali sa aming mga
              laro</p>
            <p>- Makatanggap ng hanggang 5% mataas na gantimpala ng komisyon. Matatanggap mo ang reward na ito kapag
              nakaipon ka ng sapat na Min 100P. Ang lahat ng impormasyon ay ganap na na-update namin, maaari mong sundin
              ang mga pang-araw-araw na update</p>
            <p> - Kung mas maraming kaibigan ang iniimbitahan mo, mas malaki ang iyong mga reward!</p>
            <p><strong>Paano sumali:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- I-click pumunta sa menu >> I-click piliin ang: /affiliate</p>
            <p><strong>Step 2</strong></p>
            <p>- Paraan 1: kopyahin at ipadala ang link ng iyong referral code at ipadala ito sa iyong kaibigan</p>
            <p>- Paraan 2: click share button => ipadala ang link ng iyong referral code sa iyong kaibigan</p>
            <p>Panoorin ang pagtaas ng iyong komisyon araw-araw habang mas marami sa iyong mga kaibigan ang sumali</p>

            <h4>Giftcode</h4>
            <p>Ang gift code ay isang feature kung saan maaari kang maglagay ng mga gift code para makatanggap ng mga
              reward mula sa aming mga regalo. Magkakaroon kami ng mga kaganapan na may kaakit-akit na mga code ng
              regalo, na may magagandang gantimpala, mangyaring subaybayan ang aming channel nang regular upang hindi mo
              ito makaligtaan.</p>
            <p><strong>Paano maglagay ng giftcode:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- I-click pumunta sa menu >> I-click piliin ang: /giftcode</p>
            <p><strong>Step 2</strong></p>
            <p>- Ilagay ang giftcode</p>
            <p>- Ang regalo ay ipapadala sa iyo sa sandaling matagumpay na naipasok ang code</p>

            <h4>Account</h4>
            <p><strong>Paano suriin</strong></p>
            <p>I-click pumunta sa menu >> I-click piliin ang /account</p>
            <p>Ang impormasyon tungkol sa iyong account ay ipapakita</p>

            <h4>Analytics</h4>
            <p>Gusto mo bang madagdagan ang iyong pagkakataong manalo? Narito ang aming bagong feature na "Analysis
              Dashboard" para tulungan ka! Gamitin ang feature na ito para mahulaan ang mga resulta at maglaro nang mas
              matalino, manalo nang mas malaki at makita ang iyong panalong rate na tumataas</p>
            <p><strong>Paano tingnan ang talahanayan ng pagsusuri:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- I-click pumunta sa menu >> I-click piliin ang /analytics</p>
            <p><strong>Step 2</strong></p>
            <p>- I-click upang piliin ang data na gusto mong tingnan (sa iyo o sa iba pa)</p>
            <p>- Ipapakita namin ang istatistikal na pagsusuri ng huling 1000 larong nilaro. Maaari kang umasa doon
              upang kalkulahin at gawin ang pinaka-angkop na desisyon upang ilagay ang pinto. Malaking panalo ang
              naghihintay sa iyo</p>

            <h3 class="text-center">Cash IN / Cash OUT</h3>

            <h4>Cashin</h4>
            <p>Maligayang pagdating sa aming tampok na in-game na deposito. Sa ilang simpleng hakbang lamang, maaari
              kang magdagdag ng karagdagang kapital sa iyong account upang magkaroon ng pagkakataong kumita ng mas
              maraming pera.</p>
            <p><strong>Paano mag Cash In:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- Paraan 1: Pumunta sa menu at piliin ang: /cashin</p>
            <p>- Paraan 2: Sa toolbar, hanapin at piliin ang button na " 💵 cash in💵 "</p>
            <p><strong>Step 2</strong></p>
            <p>- Maglipat ng pera sa aming Gcash number ayon sa impormasyong ipinapakita sa laro</p>
            <p><strong class="text-red">⚠️Tandaan:</strong></p>
            <p>- Ang halagang inilipat ay ayon sa halaga ng mukha: 10p, 20P.... makakatanggap ka ng 1:1 exchange rate.
              Kung maglilipat ka ng mas mababa sa 10P makakatanggap ka lamang ng 1 chip.</p>
            <p>- Ang aming mga Gcash account number ay maaaring magbago nang madalas. Dapat mong suriin ang iyong
              impormasyon sa /Cashin bago ang bawat sesyon ng paglilipat ng pera para sa pinakamabilis at pinakaligtas
              na transaksyon.</p>
            <p><strong>Step 3</strong></p>
            <p>- Ilagay ang impormasyon sa bill ng transaksyon sa paglilipat na ginawa mo lamang upang makatanggap ng
              pera sa iyong account sa laro</p>
            <p>- 1: click /Cashinref ( go to the menu /Cashinref or select immediately below Cashin information " ipasok
              ang Ref.no para makumpleto" )</p>
            <p>- 2: ilagay ang huling 6 na character ng ref.no code (reference ID) sa transfer transaction bill</p>
            <p>- 3: ilagay ang iyong Gcash number (ang Gcash number na ginamit mo upang ilipat sa aming Gcash account)
              sa format na: 0xxxxxxx</p>
            <p>- 4 : ilagay ang halagang inilipat mo</p>
            <p>- 5: suriin muli ang impormasyong inilagay mo</p>
            <p>- Kung ito ay tama, piliin ang "kumpirmahin" => ang transaksyon ay matagumpay, ang pera ay awtomatikong
              idaragdag sa iyong account</p>
            <p>- Kung mali ang impormasyon at gusto mong muling ipasok ito, mangyaring bumalik sa Hakbang 3</p>
            <p>SUPPORTA: Kung kailangan mo ng anumang suporta, mangyaring makipag-ugnay sa amin sa <a
                href="https://t.me/+PwBYFHAHg3g3OTk1" target="_blank">LuckGcash support
              </a></p>

            <h4>Cashout</h4>
            <p>Kung naglalaro ka, manalo ng malaki at gustong mag-withdraw ng pera sa iyong Gcash account, piliin ang
              aming Cashout feature. Dito maaari kang mag-withdraw ng pera nang mabilis at ligtas</p>
            <p><strong>Paano mag Cashout:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- Piliin ang pindutan ng Cash out</p>
            <p>- Paraan 1: I-click pumunta sa menu at piliin ang: /cashout</p>
            <p>- Paraan 2: Sa toolbar, hanapin at piliin ang button na "💸 cash out 💸"</p>
            <p><strong>Step 2</strong></p>
            <p>- Piliin ang iyong e-wallet: i-click ang "Gcash"</p>
            <p><strong>Step 3</strong></p>
            <p>Ilagay ang iyong Gcash account number</p>
            <p><strong>Step 4</strong></p>
            <p>- Ilagay ang halagang gusto mong bawiin . Pagkatapos ay i-click ang kumpirmahin</p>
            <p>- Ang matagumpay na transaksyon, ibabalik ang pera sa iyong GCash account</p>
            <p>- Maglaro ng malaki, manalo ng malaki at i-withdraw ang iyong pera ngayon</p>
            <p>SUPPORTA: Kung kailangan mo ng anumang suporta, mangyaring makipag-ugnay sa amin sa <a
                href="https://t.me/+PwBYFHAHg3g3OTk1" target="_blank">LuckGcash support
              </a></p>

            <h3 class="text-center">GAME</h3>

            <h4>🎲 Mataas/Mababa 🎲</h4>
            <p>Handa ka na bang subukan ang iyong suwerte at kakayahan? Ang aming bagong Mataas/Mababa dice game ay
              simple, masaya at nagbibigay sa iyo ng pagkakataong manalo ng MALAKING!</p>
            <p><strong>Mga panuntunan sa laro:</strong></p>
            <p>- Igulong ang dice at hulaan kung ang magiging resulta ay Mataas o Mababa.</p>
            <p>- Mataas : Ang halaga ng dice ay isa sa mga sumusunod na numero: 4,5,6</p>
            <p>- Mababa : Ang halaga ng dice ay isa sa mga sumusunod na numero: 1,2,3</p>
            <p>- Kung ang mga dice na iyong iginulong ay tumugma sa iyong mataas/mababa taya, ikaw ay mananalo</p>
            <p>-Ratio : x1.85</p>
            <p>Halimbawa, tumaya ka ng mataas: <br>
              - Kung ang halaga ng dice ay isa sa mga numerong 4,5,6 pagkatapos ay panalo ka <br>
              - Kung ang halaga ng dice ay isa sa mga numerong 1,2,3 natalo mo</p>
            <p><strong>Paano maglaro:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- Mag-click sa pindutang laro 🎲 Mataas/mababa 🎲</p>
            <p><strong>Step 2</strong></p>
            <p>- I-click upang piliin kung tumaya sa mataas o mababa</p>
            <p><strong>Step 3</strong></p>
            <p>- Piliin ang halaga ng taya (kung gusto mong subukan ito ng libre - piliin ang "Subukan", kung gusto mong
              kanselahin ang laro piliin ang "Pag-atras)</p>
            <p><strong>Step 4</strong></p>
            <p>- Mag-click sa icon ng dice 🎲 para gumulong kung kumpirmahin mong maglaro. I-click ang Pag-atras kung
              gusto mong kanselahin ang iyong turn</p>
            <p>Ang mga resulta ay ibabalik kaagad pagkatapos mong gumulong ng dice</p>
            <p><strong class="text-red">⚠️Tandaan:</strong> Kung gusto mong magpatuloy sa paglalaro na may parehong
              antas ng taya at window ng pagtaya
              gaya ng nakaraang laro, i-click lang muli ang icon ng dice 🎲, awtomatikong ire-record ng system ang
              impormasyon at ibabalik ang mga resulta sa iyo. Kung gusto mong ayusin ang antas ng iyong taya, mangyaring
              muling piliin ang laro at magsimulang muli sa simula (hakbang 1).</p>

            <p class="text-center"><i class="fa-solid fa-arrow-right"></i> Maglaro ngayon <a target="_blank"
                :href="telegramLink">LuckyGCash.com</a> <i class="fa-solid fa-arrow-left"></i></p>

            <h4>🎲 Even/odd 🎲</h4>
            <p>Sa tingin mo ba mahuhulaan mo ang mga resulta? Sumali sa aming kapana-panabik na Odd-Even dice game at
              manalo ng malaki sa bawat roll ng dice!</p>
            <p><strong>Mga panuntunan sa laro:</strong></p>
            <p>- Igulong ang dice at hulaan kung ang magiging resulta ay Even o Odd.</p>
            <p>- Even : Ang halaga ng dice ay isa sa mga sumusunod na numero: 2, 4, 6</p>
            <p>- Odd : Ang halaga ng dice ay isa sa mga sumusunod na numero: 1, 3, 5</p>
            <p>- Kung ang mga dice na iyong iginulong ay tumugma sa iyong Even/Odd taya, ikaw ay mananalo</p>
            <p>- Ratio : x1.85</p>
            <p>Halimbawa, tumaya ka ng Even: <br>
              - Kung ang halaga ng dice ay isa sa mga numerong 2, 4, 6 pagkatapos ay panalo ka <br>
              - Kung ang halaga ng dice ay isa sa mga numerong 1, 3, 5 natalo mo</p>
            <p><strong>Paano maglaro:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- Mag-click sa pindutang laro 🎲 Even/Odd 🎲</p>
            <p><strong>Step 2</strong></p>
            <p>- I-click upang piliin kung tumaya sa: Even o Odd</p>
            <p><strong>Step 3</strong></p>
            <p>- Piliin ang halaga ng taya (kung gusto mong subukan ito ng libre - piliin ang "Subukan", kung gusto mong
              kanselahin ang laro piliin ang "Pag-atras)</p>
            <p><strong>Step 4</strong></p>
            <p>- Mag-click sa icon ng dice 🎲 para gumulong kung kumpirmahin mong maglaro. I-click ang Pag-atras kung
              gusto mong kanselahin ang iyong turn</p>
            <p>Ang mga resulta ay ibabalik kaagad pagkatapos mong gumulong ng dice</p>
            <p><strong class="text-red">⚠️Tandaan:</strong> Kung gusto mong magpatuloy sa paglalaro na may parehong
              antas ng taya at window ng pagtaya gaya ng
              nakaraang laro, i-click lang muli ang icon ng dice 🎲, awtomatikong ire-record ng system ang impormasyon
              at ibabalik ang mga resulta sa iyo. Kung gusto mong ayusin ang antas ng iyong taya, mangyaring muling
              piliin ang laro at magsimulang muli sa simula (hakbang 1).</p>

            <p class="text-center"><i class="fa-solid fa-arrow-right"></i> Maglaro ngayon <a target="_blank"
                :href="telegramLink">LuckyGCash.com</a> <i class="fa-solid fa-arrow-left"></i></p>

            <h4>🎲 Hulaan Ang Numero 🎲</h4>
            <p>Try your luck and skills in our new 🎲 Hulaan Ang Numero 🎲 Game! Just predict the correct side of the
              dice and win big rewards!</p>
            <p><strong>Mga panuntunan sa laro:</strong></p>
            <p>- Pagulungin ang dice, hulaan kung ano ang magiging halaga ng dice</p>
            <p>- Kung ang resulta na ibinalik ng mga dice ay tumutugma sa numero na iyong tinaya. panalo ka</p>
            <p>Halimbawa, tumaya ka sa numero 6:</p>
            <p>- Kung ang halaga ng dice ay 6 pagkatapos ay manalo ka</p>
            <p>- Kung ang halaga ng dice ay isa sa mga numerong 1, 2, 3, 4, 5 natalo mo</p>
            <p>- Ratito : x5</p>
            <p><strong>Paano maglaro:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- Mag-click sa pindutang laro 🎲 Hulaan Ang Numero 🎲 </p>
            <p><strong>Step 2</strong></p>
            <p>- Mag-click sa numerical value na gusto mong tayaan</p>
            <p><strong>Step 3</strong></p>
            <p>- Piliin ang halaga ng taya (kung gusto mong subukan ito ng libre - piliin ang "Subukan", kung gusto mong
              kanselahin ang laro piliin ang "Pag-atras)</p>
            <p><strong>Step 4</strong></p>
            <p>- Mag-click sa icon ng dice 🎲 para gumulong kung kumpirmahin mong maglaro. I-click ang Pag-atras kung
              gusto mong kanselahin ang iyong turn</p>
            <p>Ang mga resulta ay ibabalik kaagad pagkatapos mong gumulong ng dice</p>
            <p><strong class="text-red">⚠️Tandaan:</strong> Kung gusto mong magpatuloy sa paglalaro na may parehong
              antas ng taya at window ng pagtaya gaya ng
              nakaraang laro, i-click lang muli ang icon ng dice 🎲, awtomatikong ire-record ng system ang impormasyon
              at ibabalik ang mga resulta sa iyo. Kung gusto mong ayusin ang antas ng iyong taya, mangyaring muling
              piliin ang laro at magsimulang muli sa simula (hakbang 1).</p>
            <p class="text-center"><i class="fa-solid fa-arrow-right"></i> Maglaro ngayon <a target="_blank"
                :href="telegramLink">LuckyGCash.com</a> <i class="fa-solid fa-arrow-left"></i></p>
            <!-- Slot TELEGRAM -->
            <h4>🎰🎰 Slot TELEGRAM 🎰🎰</h4>
            <p>Maghanda para sa walang tigil na mga kilig sa aming bagong laro ng Slot! Paikutin ang mga reels at ihanay
              ang mga simbolo para sa iyong pagkakataong manalo ng malaki.</p>
            <p><strong>Mga panuntunan sa laro:</strong></p>
            <p>- Kinakalkula ang mga resulta pagkatapos ng bawat pag-ikot</p>
            <p>- Kung ang resulta ay 2 magkaparehong larawan Makakabawi ka ng 10% ng iyong stake.</p>
            <p>- Ang resulta ay 3 magkaparehong larawan ang panalo. x10</p>
            <p>- Ang mga espesyal na resulta ay 7️⃣7️⃣7️⃣ panalo x25</p>
            <p><strong>Paano maglaro:</strong></p>
            <p><strong>Step 1</strong></p>
            <p>- I-click upang piliin ang laro ng Slot</p>
            <p><strong>Step 2</strong></p>
            <p>- Piliin ang antas ng spin bet (kung gusto mong subukan ito ng libre - piliin ang "Subukan", kung gusto
              mong kanselahin ang laro piliin ang "Pag-atras)</p>
            <p><strong>Step 3</strong></p>
            <p>- Mag-click sa icon ng Slot 🎰 para gumulong kung kumpirmahin mong maglaro. I-click ang Pag-atras kung
              gusto mong kanselahin ang iyong turn</p>
            <p><strong class="text-red">⚠️Tandaan:</strong> Kung gusto mong ipagpatuloy ang pag-ikot sa parehong taya
              tulad ng nakaraang laro, i-click lang muli ang
              icon ng slot, awtomatikong ire-record ng system ang impormasyon at ibabalik sa iyo ang mga resulta. Kung
              gusto mong ayusin ang antas ng iyong taya, mangyaring muling piliin ang laro at magsimulang muli sa simula
              (hakbang 1).</p>
            <p>Ang mga resulta ay binabayaran kaagad pagkatapos ng iyong pag-ikot</p>
            <p class="text-center"><i class="fa-solid fa-arrow-right"></i> Maglaro ngayon <a target="_blank"
                :href="telegramLink">LuckyGCash.com</a> <i class="fa-solid fa-arrow-left"></i></p>
            <!-- Jackpot -->
            <h4>Jackpot</h4>
            <p>Maghanda para sa pinakakapana-panabik na bahagi ng laro – ang Jackpot! Sa bawat roll ng dice, mas malapit
              ka sa malaking premyo na maaaring magbago ng iyong buhay.</p>
            <p><strong>Mga panuntunan sa laro:</strong></p>
            <p>- Maglaro ng anumang dice game (even, odd, mataas, mababa, hulaan ang numero)</p>
            <p>- Makukuha mo ang jackpot kapag lumabas ang dice 6️⃣ sa 4 magkasunod na beses, panalo man o matalo ang
              resulta ng iyong taya</p>
            <p><strong class="text-red">⚠️Tandaan:</strong> % Jackpot na makukuha mo depende sa halaga ng taya mo sa
              ika-4 magkasunod na roll ng 6️⃣</p>
            <p>Halimbawa:</p>
            <p>✅ 1st time: Tumaya ka ng 50₱, dice roll 6️⃣</p>
            <p>✅ 2nd and 3rd time: Tumaya ka ng 200₱, dice roll 6️⃣</p>
            <p>✅ 4th time : Tumaya ka ng 1,000₱, dice roll 6️⃣ (Makakatanggap ka ng 50% ng halaga ng jackpot, hindi
              alintana kung tumaya ka ng Mataas, Mababa, Even, Odd)</p>

          </div>

          <div class="p-0 text-center">
            <p v-if="!linkInfo || linkInfo.link_id === 'not-link'">
              HINDI NAKA-LINK ANG IYONG TELEGRAM ACCOUNT
            </p>
            <p v-else>Naka-link ka sa isang bot: {{ linkInfo.bot_name }}</p>
            <a :href="computedLink" class="lk-tele" target="_blank">
              {{
                "MAGLARO NGAYON"
              }}
            </a>
          </div>

          <!-- <a
            :href="linkInfo ? linkInfo.link_deep_link : '#'"
            class="lk-tele"
            target="_blank"
          >
            {{
              !linkInfo || linkInfo.link_id === "not-link"
                ? "I-LINK NGAYON"
                : "MAGLARO NGAYON"
            }}
          </a> -->
        </div>
      </div>
    </div>
    <!-- <div class="col-12">
      <div class="dashbox">
        <div class="dashbox-title">
          <h2>Mga instruction video</h2>
        </div>
        <div class="video-game">
          <div class="video-container"><iframe src="https://www.youtube.com/embed/2lkbDUW1zSk?si=yakThFK4zUzL0t1B"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
import { fetchLinkInfo } from "@/api/link-tele";

export default {
  data() {
    return {
      linkInfo: null,
      error: null,
      username: "your_username", // Thay thế bằng giá trị thực tế của username
      isLoggedIn: false, // Thêm biến để theo dõi trạng thái đăng nhập
    };
  },
  computed: {
    telegramLink() {
      return `https://t.me/lucky_gcash_bot?start=ref=${this.username}`;
    },
    computedLink() {
      if (
        this.isLoggedIn &&
        this.linkInfo &&
        this.linkInfo.link_id !== "not-link"
      ) {
        return this.linkInfo.link_deep_link;
      } else {
        return this.telegramLink;
      }
    },
  },
  methods: {
    async getLinkInfo() {
      try {
        const data = await fetchLinkInfo();
        if (data && data.data) {
          this.linkInfo = data.data;
        }
      } catch (error) {
        console.error("Error fetching link info:", error);
        this.error = error.response ? error.response.data : error.message;
      }
    },
    checkLoginStatus() {
      // Kiểm tra trạng thái đăng nhập của người dùng
      const token = localStorage.getItem("access_token");
      this.isLoggedIn = !!token;
    },
  },
  mounted() {
    this.checkLoginStatus();
    if (this.isLoggedIn) {
      this.getLinkInfo();
    }
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 ratio */
  height: 0;
  overflow: hidden;
}



.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
