// src/api/register.js
import { apiClientV2 } from "./apiClient";

// Đăng ký người dùng
export const registerUser = async (
  username,
  password,
  captchaId,
  captchaCode,
  utmInfo
) => {
  try {
    const response = await apiClientV2.post("/register", {
      username,
      password,
      captcha_id: captchaId,
      captcha_code: captchaCode,
      utm_info: utmInfo,
    });

    return response.data;
  } catch (error) {
    // Kiểm tra xem phản hồi có tồn tại và có chứa lỗi không
    if (error.response) {
      if (error.response.status === 400) {
        return {
          success: false,
          errorDetail: error.response.data.error.detail,
        };
      } else if (error.response.status === 500) {
        return {
          success: false,
          errorDetail: error.response.data.error.detail,
        };
      }
    }
    // Nếu không có phản hồi từ server, báo lỗi kết nối
    throw new Error("Connection error, please check again");
  }
};
