// src/plugins/vuetify.js

import { createVuetify } from "vuetify";
import "vuetify/styles";

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi",
  },
});

export default vuetify;
