<template>
  <div class="col-12">
    <game-tele/>
  </div>
</template>

<script>
import GameTele from '../components/GameTele.vue';
export default {
  components: { GameTele },
  name: "LienKetTele",
  component:{
    GameTele,
  }
};
</script>

<style>
.lk-tele {
  border-radius: 8px;
  background: linear-gradient(90deg, #ff5860 0%, var(--main-color) 100%);
  box-shadow: 0 0 16px 0 rgb(255 88 96 / 30%);
  position: relative;
  color: #fff;
  padding: 5px 15px;
}
</style>