import { apiClientV1 } from "./apiClient";

export const updatePaymentMethod = async (wallet_id, ref_id) => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.post(
      "/payment/bind",
      { wallet_id, ref_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail || "Registration failed";
    console.log(errorMessage);
  }
};

export const getLinkedPaymentMethod = async () => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get("/payment", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail || "Failed to fetch payment method";
    console.log(errorMessage);
  }
};

export const unbindWallet = async () => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    throw new Error("No token found, please log in again.");
  }
  try {
    const response = await apiClientV1.post(
      "/payment/unbind",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail || "Failed to unbind wallet";
    throw new Error(errorMessage);
  }
};
