<template>
 <TablePlayHistory/>
</template>

<script>
import TablePlayHistory from "@/components/tablePlayHistory.vue";
import { useHead } from "@vueuse/head";
export default {
  name: "LichSuChoi",
  setup() {
    useHead({
      title: "Play History",
    });
  },
  components:{
    TablePlayHistory,
  }
};
</script>

<style>
</style>