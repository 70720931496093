// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/HomePage.vue";
import PlayGame from "./views/PlayPage.vue";
import BankSetup from "./components/BankSetup.vue";
import Nhiem_vu_hang_ngay from "./views/Nhiem_vu_hang_ngayPage.vue";
import Jackpot from "./views/Jackpot.vue";
import Diem_danh from "./views/Diem_danhPage.vue";
import Tro_thanh_ctv from "./views/Tro_thanh_ctvPage.vue";
import Dang_ky_Form from "./components/RegisterForm.vue";
import Dang_nhap_Form from "./components/LoginForm.vue";
import LichSuChoi from "./views/Lich_su_choiPage.vue";
import LienKetTele from "./views/Lien_ket_telePage.vue";
import ChangePass from "./components/ChangePass.vue";
import ErrorPage from "./views/ErrorPage.vue";
import Vip from "./views/Vip_page.vue";
// import { getLinkedPaymentMethod } from "@/api/payment";

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/play", name: "Play", component: PlayGame },
  { path: "/bank", name: "BankSetup", component: BankSetup },
  { path: "/mission", component: Nhiem_vu_hang_ngay },
  { path: "/jackpot", component: Jackpot },
  { path: "/roll-call", component: Diem_danh },
  { path: "/collaborators", component: Tro_thanh_ctv },
  { path: "/log-in", component: Dang_nhap_Form },
  { path: "/register", component: Dang_ky_Form },
  { path: "/play-history", component: LichSuChoi },
  { path: "/telegram-link", component: LienKetTele },
  { path: "/change-password", component: ChangePass },
  { path: "/vip", component: Vip },
  {
    path: "/:catchAll(.*)", // Updated catch-all route
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// const isLoggedIn = () => {
//   return !!localStorage.getItem("access_token");
// };

// router.beforeEach(async (to, from, next) => {
//   // Nếu người dùng đã đăng nhập
//   if (isLoggedIn()) {
//     // Nếu người dùng cố gắng truy cập trang chủ
//     if (to.path === "/") {
//       try {
//         const response = await getLinkedPaymentMethod();
//         const data = response.data; // Lấy dữ liệu từ phản hồi
//         if (!data || !data.wallet_id) {
//           // Nếu người dùng chưa thiết lập ngân hàng, chuyển hướng đến trang thiết lập ngân hàng
//           return next({ name: "BankSetup" });
//         }
//       } catch (error) {
//         console.error("Error fetching linked payment method:", error);
//         return next({ path: "/error" });
//       }
//     }
//   }
//   // Cho phép truy cập các trang khác
//   next();
// });
const isLoggedIn = () => {
  return !!localStorage.getItem("access_token");
};

router.beforeEach((to, from, next) => {
  const protectedRoutes = [
    "/bank",
    "/telegram-link",
    "/change-password",
    "/play-history",
  ];

  // Kiểm tra nếu người dùng chưa đăng nhập và đang cố gắng truy cập các trang cần bảo vệ
  if (protectedRoutes.includes(to.path) && !isLoggedIn()) {
    return next({ path: "/log-in" }); // Chuyển hướng đến trang đăng nhập
  }

  // Cho phép truy cập các trang khác
  next();
});
export default router;
