<template>
  <div class="col-12">
    <div class="dashbox">
      <div class="dashbox-title">
        <h2>Play history</h2>
      </div>
      <div class="overflow-x-auto p-0">
        <table class="game-table">
          <thead>
            <tr>
              <th>Game id</th>
              <th>Game</th>
              <th>Taya</th>
              <th>Perang natanggap</th>
              <th>Pinili</th>
              <th>Resulta</th>
              <th>Oras</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v, k) in paginatedHistory" :key="k">
              <td>{{ v.session }}</td>
              <td>{{ v.game_code }}</td>
              <td>{{ v.amount }}</td>
              <td>{{ v.game_code == "BIGSMALL" ? (JSON.parse(v.result)?.result == 1 ? v.amount : 0) : 0 }}</td>
              <td>{{ v.game_code == "BIGSMALL" ? (JSON.parse(v.result)?.str_note || "").toUpperCase() : ( v.game_code == "SLOT" ? "" : JSON.parse(v.result)?.Bet || "" ) }}</td>
              <td>{{ v.game_code == "BIGSMALL" ? (JSON.parse(v.result)?.result == 1 ? "WON" : "LOST") : (v.win_amount > 0 ? "WON" : "LOST") }}</td>
              <td>{{ v.updated_at !== "" ? new Date(v.updated_at).toLocaleString() : "" }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-controls">
          <button @click="prevPage" :disabled="currentPage === 1">Prev</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHistoryGamePlaysMethod } from '@/api/history.js'

export default {
  name: "TablePlayHistory",
  data() {
    return {
      history: [],
      currentPage: 1,
      itemsPerPage: 10
    }
  },
  created() {
    this.getHistory()
  },
  computed: {
    totalPages() {
      return Math.ceil(this.history.length / this.itemsPerPage)
    },
    paginatedHistory() {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.history.slice(start, end)
    }
  },
  methods: {
    async getHistory() {
      try {
        let r = await getHistoryGamePlaysMethod(20, 0)
        if (r) {
          for (const d of r.data) {
            this.history.push(d)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    }
  }
};
</script>

<style>
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination-controls span {
  margin: 0 10px;
}
</style>
