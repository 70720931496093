<template>
  <div class="row">
    <div class="col-12 col-xl-6">
      <div class="dashbox">
        <div class="dashbox-title">
          <h2>Naglilipat ng pera ang bangko</h2>
        </div>
        <div class="game-describe">
          Kailangan mong maglaro ng kahit 1 laro at gamitin ang ref.no na iyon para i-link ang wallet. At ibigay ang mga sumusunod na benepisyo pagkatapos i-link ang wallet. Mga Benepisyo: Makakuha kaagad ng 5 Pesos para sa pang-araw-araw na pag-check-in. Itala ang iyong kabuuang halaga ng paglalaro at makakuha ng pang-araw-araw na mission reward hanggang 225 Pesos/araw
        </div>
        <form
          @submit.prevent="submitForm"
          class="text-center w-100 position-relative"
        >
          <p v-if="message" :class="messageType">{{ message }}</p>
          <div class="form-group">
            <select
              autocomplete="off"
              v-model="transferBank.bin"
              name="bin"
              class="form-control sign__selectjs"
              :disabled="isDisabled"
            >
              <option value="GCash">GCash</option>
              <!-- Bạn có thể thêm nhiều tùy chọn khác nếu cần -->
            </select>
          </div>
          <div class="form-group">
            <input
              autocomplete="off"
              v-model="transferBank.wallet_id"
              name="number"
              class="form-control"
              placeholder="Ilagay ang account number"
              type="text"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <input
              autocomplete="off"
              v-model="transferBank.ref"
              name="number"
              class="form-control"
              placeholder="Ilagay ang ref number"
              type="text"
              :disabled="isDisabled"
            />
          </div>
          <div class="btn-setbank">
            <button
              class="bg-gradient-button"
              @click="confirmUnbindWallet"
              v-if="linkedPayment && linkedPayment.wallet_id"
            >
              <span>Unbind Wallet</span>
            </button>
            <button class="bg-gradient-button" type="submit">
              <span>SUBMIT</span>
            </button>
          </div>
        </form>
        <!-- Popup thông báo -->
        <div
          v-if="showRewardPopup"
          class="popup-overlay"
          @click="hideRewardPopup"
        >
          <div class="reward-popup" @click.stop>
            <span class="close-btn" @click="hideRewardPopup">&times;</span>
            <div class="reward-popup-content">
              <h2 id="wallet_success">Paunawa sa kumpirmasyon ng pitaka</h2>
              <p>{{ rewardNotification }}</p>
              <button class="bg-gradient-button" @click="redirectToHome">
                Maglaro Ngayon
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="dashbox">
        <div class="dashbox-title">
          <h2>Mga instruction video</h2>
        </div>
        <div class="video-game">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/eKqKozVVibo?si=Ao_fu0YiYvZWcFC4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  updatePaymentMethod,
  getLinkedPaymentMethod,
  unbindWallet,
} from "@/api/payment";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    useHead({
      title: "Mga setting ng bangko",
    });
  },
  data() {
    return {
      transferBank: {
        bin: "GCash",
        wallet_id: "",
        ref: "",
      },
      linkedPayment: null,
      message: "",
      messageType: "",
      showRewardPopup: false,
      rewardNotification: "",
    };
  },
  methods: {
    async submitForm() {
      this.message = "";
      this.messageType = "";
      if (this.transferBank.wallet_id.length < 9) {
        this.message =
          "Di-wasto ang account number ng GCash wallet. Pakisubukang muli.";
        this.messageType = "error";
        return;
      }
      try {
        await updatePaymentMethod(
          this.transferBank.wallet_id,
          this.transferBank.ref
        );
        this.message = "Matagumpay mong na-update ang iyong GCash wallet!";
        this.messageType = "success";
        this.showRewardPopup = true; // Hiển thị popup
        this.rewardNotification =
          "Matagumpay mong na-update ang iyong GCash wallet!"; // Nội dung thông báo
        await this.getLinkedPaymentMethod();
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.message =
            "Ang GCash wallet account number ay invalid o ginagamit na. Pakisubukang muli";
        } else {
          this.message = `${error.message}`;
        }
        this.messageType = "error";
      }
    },
    async getLinkedPaymentMethod() {
      try {
        const data = await getLinkedPaymentMethod();
        this.linkedPayment = data.data;
        if (this.linkedPayment) {
          this.transferBank.wallet_id = this.linkedPayment.wallet_id || "";
          this.transferBank.ref = this.linkedPayment.ref || "";
        }
      } catch (error) {
        this.message = `May naganap na error, pakisubukang muli`;
        this.messageType = "error";
      }
    },
    async unbindWallet() {
      this.message = "";
      this.messageType = "";
      try {
        await unbindWallet();
        this.message = "Unbound wallet successfully!";
        this.messageType = "success";
        this.linkedPayment = null;
        this.transferBank.wallet_id = "";
      } catch (error) {
        this.message = `${error.message}`;
        this.messageType = "error";
      }
    },
    confirmUnbindWallet() {
      const confirmation = window.confirm(
        "Kung mag-a-unlink ka, hindi na mabibilang para sa account na ito ang anumang mga transaksyon sa direktang paglalaro sa GCash. Sigurado ka bang gusto mong i-unlink?"
      );
      if (confirmation) {
        this.unbindWallet();
      }
    },
    hideRewardPopup() {
      this.showRewardPopup = false;
    },
    redirectToHome() {
      this.$router.push("/");
    },
  },
  async mounted() {
    await this.getLinkedPaymentMethod();
  },
};
</script>

<style scoped>
.btn-setbank > button {
  width: 35%;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-popup {
  background-color: #2b2b31;
  width: 450px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.reward-popup-content {
  text-align: center;
}

.reward-popup h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.reward-popup button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reward-popup button:hover {
  background-color: #0056b3;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #aaaaaa;
}

.close-btn:hover {
  color: #000000;
}
</style>
