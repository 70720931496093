<!-- /src/components/popup/RewardPopup.vue -->
<template>
  <div v-if="visible" class="popup-overlay" @click="hidePopup">
    <div class="notification-popup" @click.stop>
      <span class="close-btn" @click="hidePopup">&times;</span>
      <div class="notification-popup-content">
        <h2>Notification para sa rewards</h2>
        <p>Ang reward ay nailipat na sa Telegram game!</p>
        <button class="bg-gradient-button" @click="redirectToTelegramGame">
          Kumuha ng rewards at maglaro ngayon
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLinkInfo } from "@/api/link-tele";

export default {
  data() {
    return {
      linkInfo: null, // Biến để lưu thông tin liên kết
      error: null, // Biến để lưu thông báo lỗi
      visible: false,
    };
  },
  async created() {
    await this.getLinkInfo();
  },
  methods: {
    hidePopup() {
      this.visible = false;
    },
    async getLinkInfo() {
      try {
        const data = await fetchLinkInfo();

        if (data && data.data) {
          this.linkInfo = data.data;
        }
      } catch (error) {
        console.error(error);
        this.error = error.response ? error.response.data : error.message;
      }
    },
    redirectToTelegramGame() {
      if (this.linkInfo && this.linkInfo.link_deep_link) {
        window.open(this.linkInfo.link_deep_link, "_blank");
      } else {
        console.error("Link deep link không khả dụng.");
      }
    },
  },
};
</script>

<style scoped>


</style>
