import { apiClientV1 } from "./apiClient";

// Hàm để lấy thông tin người dùng hiện tại từ token
export const getCurrentUser = async (token) => {

  try {
    const response = await apiClientV1.get("/current/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Giả sử API trả về dữ liệu người dùng trong response.data
  } catch (error) {
    console.error("Failed to get current user:", error);
    throw error;
  }
};
