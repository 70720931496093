<template>
  <div class="float-contact">
    <div class="chat-messeger">
      <a href="https://www.facebook.com/messages/t/415611434958322" target="_blank">
        <img
          title="Chat Telegram"
          src="../../public/images/Messenger.png"
          alt="messeger-icon"
      /></a>
    </div>
    <div class="chat-telegram">
      <a href="https://t.me/+PwBYFHAHg3g3OTk1" target="_blank">
        <img
          title="Chat Telegram"
          src="../../public/images/Artboard-16.png"
          alt="telegram-icon"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CtaSetup",
};
</script>

<style scoped>
.float-contact {
  position: fixed;
  z-index: 9;
}
.chat-messeger {
  display: block;
  position: fixed;
  right: 15px;
  bottom: 15%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #49bce9;
  -webkit-animation: devvn_zoom 1.5s infinite linear;
  -moz-animation: devvn_zoom 1.5s infinite linear;
  -o-animation: devvn_zoom 1.5s infinite linear;
  animation: devvn_zoom 1.5s infinite linear;
  box-shadow: 0 0 0 0 #1cb2ed;
}
.chat-telegram {
  display: block;
  position: fixed;
  right: 15px;
  bottom: 5%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #49bce9;
  -webkit-animation: devvn_zoom 1.5s infinite linear;
  -moz-animation: devvn_zoom 1.5s infinite linear;
  -o-animation: devvn_zoom 1.5s infinite linear;
  animation: devvn_zoom 1.5s infinite linear;
  box-shadow: 0 0 0 0 #1cb2ed;
}
.chat-messeger img,
.chat-telegram img {
  width: 35px;
  height: 35px;
  -webkit-animation: calltrap_spin 3s infinite linear;
  -moz-animation: calltrap_spin 3s infinite linear;
  -o-animation: calltrap_spin 3s infinite linear;
  animation: calltrap_spin 3s infinite linear;
}
@-webkit-keyframes devvn_zoom {
  70% {
    box-shadow: 0 0 0 15px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
@keyframes devvn_zoom {
  70% {
    box-shadow: 0 0 0 15px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
@-webkit-keyframes calltrap_spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-moz-keyframes calltrap_spin {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-o-keyframes calltrap_spin {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes calltrap_spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>