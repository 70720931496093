<template>
  <div class="col-12 col-xl-6">
    <div class="dashbox p-0">
      <div class="dashbox-title"><h2>Landmark</h2></div>
      <div class="overflow-x-auto p-0">
        <table class="game-table table-center">
          <thead>
            <tr>
              <th>Landmark</th>
              <th>Reward</th>
              <th>% receive</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in collaborators" :key="index">
              <td>{{ item.landmark }}</td>
              <td>{{ item.reward }}</td>
              <td>{{ item.chance }}</td>
            </tr>
          </tbody>
        </table>
      </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: "TableRule",
  data() {
    return {
      collaborators: [
        { landmark: "20", reward: "10", chance: "50%" },
        { landmark: "50", reward: "20", chance: "40%" },
        { landmark: "100", reward: "30", chance: "30%" },
        { landmark: "1,000", reward: "40", chance: "4%" },
        { landmark: "5,000", reward: "100", chance: "2%" },
        { landmark: "10,000", reward: "200", chance: "2%" },
        { landmark: "20,000", reward: "500", chance: "2.5%" },
      ],
    };
  },
};
</script>