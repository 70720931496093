import { apiClientV1 } from "./apiClient";

export const getHistoryGamePlaysMethod = async (pageSize = 40, startAt = 0) => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get("/history/game-plays", {
      params: { pageSize, current: startAt },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail || "Failed to fetch history/game-plays method";
      console.log(errorMessage);
  }
};

export const getHistoryCashinMethod = async (pageSize = 40, startAt = 0) => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get("/history/cashin", {
      params: { pageSize, current: startAt },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail || "Failed to fetch history/cashin method";
      console.log(errorMessage);
  }
};

export const getHistoryCashoutMethod = async (pageSize = 40, startAt = 0) => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get("/history/cashout", {
      params: { pageSize, current: startAt },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail || "Failed to fetch history/cashout method";
      console.log(errorMessage);
  }
};
