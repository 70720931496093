<template>
  <div v-if="visible" class="popup-overlay" @click="hidePopup">
    <div class="notification-popup" @click.stop>
      <span class="close-btn" @click="hidePopup">&times;</span>
      <div class="notification-popup-content">
        <h2 id="register_success">Anunsyo</h2>
        <p>Matagumpay kang nakarehistro</p>
        <!-- <button class="bg-gradient-button" @click="redirectToPlay">
          Maglaro Ngayon
        </button> -->
      </div>
    </div>
  </div>
  <!-- <div v-if="visible" class="popup-overlay" @click="hidePopup">
    <div class="notification-popup" @click.stop>
      <span class="close-btn" @click="hidePopup">&times;</span>
      <div class="notification-popup-content">
        <h2 id="register_success">Anunsyo</h2>
        <p>Matagumpay kang nakarehistro</p>
        <button class="bg-gradient-button" @click="redirectToPlay">
          Maglaro Ngayon
        </button>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "RegisterPopup",
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    // Kiểm tra trạng thái đăng ký thành công từ localStorage
    if (localStorage.getItem("registrationSuccess") === "true") {
      this.visible = true;
      localStorage.removeItem("registrationSuccess"); // Xóa trạng thái sau khi hiển thị

      if (window.fbq && typeof window.fbq === "function") {
        window.fbq("track", "CompleteRegistration");
      }
      // Tự động tắt popup sau 2 giây
      setTimeout(() => {
        this.hidePopup();
      }, 2000); // 2000ms = 2s
    }
  },
  methods: {
    hidePopup() {
      this.visible = false;
    },
    // redirectToPlay() {
    //   this.hidePopup();
    //   this.$router.push("/play");
    // },
  },
};
</script>


<style scoped>
.reward-popup h2 {
  font-size: 24px;
  margin-bottom: 15px;
}
</style>