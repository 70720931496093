// api/referral.js
import { apiClientV1 } from "./apiClient";

export const getReferralStatus = async () => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get("/referral/status", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail || "Failed to fetch referral status";
      console.log(errorMessage);
  }
};

export const getReferralHistory = async () => {
  const token = localStorage.getItem("access_token");

  try {
    const response = await apiClientV1.get("/referral/history", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.detail || "Failed to fetch referral history";
      console.log(errorMessage);
  }
};
