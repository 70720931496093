// src/api/login.js
import { apiClientV1 } from "./apiClient";

// Đăng nhập
export const loginUser = async (username, password, captchaId, captchaCode) => {
  try {
    const response = await apiClientV1.post("/login", {
      username,
      password,
      captcha_id: captchaId,
      captcha_code: captchaCode,
    });
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    // Kiểm tra xem phản hồi có tồn tại và có chứa lỗi không
    if (error.response) {
      if (error.response.status === 400) {
        return {
          success: false,
          errorDetail: error.response.data.error.detail,
        };
      } else if (error.response.status === 500) {
        return {
          success: false,
          errorDetail: error.response.data.error.detail,
        };
      }
    }
    // Nếu không có phản hồi từ server, báo lỗi kết nối
    throw new Error("Connection error, please check again");
  }
};
