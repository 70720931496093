<template>
  <div class="horizontal-menu">
    <button @click="navigateToTab('even-o-odd')" variant="outline-primary">
      Even o Odd
    </button>
    <button @click="navigateToTab('maliit-malaki')" variant="outline-primary">
      Maliit - Malaki
    </button>
    <button
      @click="navigateToTab('hulaan-ang-number')"
      variant="outline-primary"
    >
      Hulaan ang number
    </button>
    <button  @click="navigateToTab('gametele')">Game sa Telegram</button>
    <!-- <button v-if="isLoggedIn && isVip" @click="navigateToTab('gametele')">Game sa Telegram</button> -->
  </div>
</template>

<script>
// import { getCurrentVipUser } from "@/api/vip";
export default {
  name: "HorizontalMenu",
  data() {
    return {
      isVip: false, // Khởi tạo giá trị ban đầu
      isLoggedIn: false, // Thêm biến để theo dõi trạng thái đăng nhập
    };
  },
  methods: {
    navigateToTab(tab) {
      this.$router.push({ name: "Play", query: { tab } });
    },
  },
  // async created() {
  //   // Giả định rằng thông tin VIP được lưu trong localStorage
  //   try {
  //     const token = localStorage.getItem("access_token");
  //     if (token) {
  //       this.isLoggedIn = true; // Cập nhật trạng thái đăng nhập
  //       const response = await getCurrentVipUser(token);
  //       console.log("VIP Response:", response); // Log the response
  //       const vipLevel = response.data;
  //       this.isVip = vipLevel >= 1; // Cập nhật trạng thái VIP nếu đủ điều kiện
  //       console.log("isVip:", this.isVip); // Log the isVip value
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
};
</script>

<style scoped>
.horizontal-menu {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  padding: 0 30px;
  background-color: #28282d;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  position: sticky; /* Thêm thuộc tính này */
  top: 0; /* Chỉ định vị trí sticky */
  z-index: 99; /* Đảm bảo menu nằm trên các thành phần khác */
  margin-bottom: 20px;
  height: 80px;
}

@media (max-width: 768px) {
  .horizontal-menu {
    top: 70px;
    height: 50px;
    font-size: 12px;
  }
}

.horizontal-menu button {
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}
.horizontal-menu button:active,
.horizontal-menu button:hover {
  color: #d5c039;
}
</style>
